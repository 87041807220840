import React, { useState, useEffect, useRef, useCallback } from 'react';
import { SlPaperPlane, SlDocs, SlNote, SlRefresh, SlLike } from "react-icons/sl";
import { FaShareSquare, FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { useCalculator } from '../context/CalculatorContext';
import { colors, spacing, commonStyles, layout } from '../styles/globalStyles';
import { interfaces, storageDevices } from '../data/transferData';
import CalculatorButtons from './CalculatorButtons';
import { handleShareOnDevice } from '../utils/shareUtils';

const formatTime = (seconds) => {
  if (seconds < 60) {
    return `${seconds.toFixed(1)} seconds`;
  } else if (seconds < 3600) {
    return `${(seconds / 60).toFixed(1)} minutes`;
  } else {
    return `${(seconds / 3600).toFixed(1)} hours`;
  }
};

const TransferTimeCalculator = ({ addLog }) => {
  const { transferCalcState, setTransferCalcState } = useCalculator();

  // Initialize state
  const [selectedInterface, setSelectedInterface] = useState(interfaces[0]);
  const [sourceStorage, setSourceStorage] = useState(storageDevices[0]);
  const [destStorage, setDestStorage] = useState(storageDevices[0]);
  const [dataSize, setDataSize] = useState('1.00 TB');
  const [transferTime, setTransferTime] = useState('');
  const [lockedField, setLockedField] = useState(null);
  const [copyText, setCopyText] = useState(<SlDocs />);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [shareButtonText, setShareButtonText] = useState(<SlPaperPlane />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [isMobile, setIsMobile] = useState(false);

  // Add refs
  const dataSizeRef = useRef(null);
  const interfaceRef = useRef(null);
  const storageRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const currentSpacing = isMobile ? spacing.mobile : spacing.desktop;

  // Update calculate transfer time to use parseFileSize
  const calculateTransferTime = () => {
    const parsed = parseFileSize(dataSize);
    if (!parsed) return;

    const { size, unit } = parsed;
    const multipliers = {
      'KB': 1/1024/1024,
      'MB': 1/1024,
      'GB': 1,
      'TB': 1024,
      'PB': 1024 * 1024
    };
    
    const dataSizeInGb = (size * multipliers[unit]) * 8;
    const effectiveSpeed = Math.min(
      sourceStorage.speed,
      destStorage.speed,
      selectedInterface.speed
    );
    const timeInSeconds = dataSizeInGb / effectiveSpeed;
    
    setTransferTime(formatTime(timeInSeconds));
  };

  useEffect(() => {
    calculateTransferTime();
  }, [selectedInterface, sourceStorage, destStorage, dataSize]);

  // Add parseFileSize from BitrateCalculator
  const parseFileSize = (input) => {
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();
    const unitPatterns = {
      'pb': ['p', 'ps', 'pb', 'pbs', 'pe', 'pet', 'pets', 'peta', 'petas', 'petabyte', 'petabytes'],
      'tb': ['t', 'ts', 'tb', 'tbs', 'te', 'ter', 'ters', 'tera', 'teras', 'terabyte', 'terabytes'],
      'gb': ['g', 'gs', 'gb', 'gbs', 'gi', 'gig', 'gigs', 'giga', 'gigas', 'gigabyte', 'gigabytes'],
      'mb': ['m', 'ms', 'mb', 'mbs', 'me', 'meg', 'megs', 'mega', 'megas', 'megabyte', 'megabytes'],
      'kb': ['k', 'ks', 'kb', 'kbs', 'ki', 'kil', 'kils', 'kilo', 'kilos', 'kilobyte', 'kilobytes'],
      'b': ['b', 'bs', 'bi', 'by', 'byte', 'bytes']
    };
  
    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})$`));
  
    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2];
  
      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }
    return null;
  };

  // Update handleDataSizeWheel
  const handleDataSizeWheel = (e) => {
    e.preventDefault();
    if (lockedField === 'dataSize') return;

    const parsed = parseFileSize(dataSize);
    if (!parsed) return;
    
    let { size, unit } = parsed;
    const delta = -e.deltaY;
    const changeFactor = Math.abs(delta) > 50 ? 1.5 : 1.1;
    
    if (delta > 0) {
      size *= changeFactor;
    } else {
      size /= changeFactor;
    }

    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    while (size >= 1000 && currentIndex < unitsOrder.length - 1) {
      size /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (size < 1 && currentIndex > 0) {
      size *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    size = unit === 'KB' ? Math.round(size) : parseFloat(size.toFixed(2));
    setDataSize(`${size} ${unit}`);
    calculateTransferTime();
  };

  // Update the wheel handlers
  const handleWheel = (e, options, currentValue, setterFunction) => {
    e.preventDefault();
    const delta = Math.sign(-e.deltaY);
    const currentIndex = options.indexOf(currentValue);
    const newIndex = Math.max(0, Math.min(currentIndex + delta, options.length - 1));
    
    if (newIndex !== currentIndex) {
      setterFunction(options[newIndex]);
    }
  };

  const handleInterfaceWheel = (e) => {
    const interfaceNames = interfaces.map(i => i.name);
    handleWheel(
      e,
      interfaceNames,
      selectedInterface.name,
      (name) => setSelectedInterface(interfaces.find(i => i.name === name) || interfaces[0])
    );
  };

  const handleSourceStorageWheel = (e) => {
    const storageNames = storageDevices.map(s => s.name);
    handleWheel(
      e,
      storageNames,
      sourceStorage.name,
      (name) => setSourceStorage(storageDevices.find(s => s.name === name) || storageDevices[0])
    );
  };

  const handleDestStorageWheel = (e) => {
    const storageNames = storageDevices.map(s => s.name);
    handleWheel(
      e,
      storageNames,
      destStorage.name,
      (name) => setDestStorage(storageDevices.find(s => s.name === name) || storageDevices[0])
    );
  };

  // Update the handleTouchStart function
  const handleTouchStart = useCallback((e, field) => {
    if (lockedField === field) return;
    
    let lastY = e.touches[0].clientY;
    const touchX = e.touches[0].clientX;
    const element = e.target;
    const rect = element.getBoundingClientRect();
    const isLeftHalf = touchX < (rect.left + rect.width / 2);
    let lastTime = Date.now();
    
    const handleTouchMove = (moveEvent) => {
      moveEvent.preventDefault();
      const currentY = moveEvent.touches[0].clientY;
      const currentTime = Date.now();
      
      // Only process if enough time has passed (throttle updates)
      if (currentTime - lastTime > 16) { // ~60fps
        const deltaY = lastY - currentY;
        const sensitivity = isLeftHalf ? 0.5 : 0.05; // Adjusted sensitivity
        
        if (Math.abs(deltaY) > 0) {
          const simulatedWheelEvent = {
            deltaY: deltaY * sensitivity,
            preventDefault: () => {}
          };
          handleDataSizeWheel(simulatedWheelEvent);
          
          lastY = currentY;
          lastTime = currentTime;
        }
      }
    };
    
    const handleTouchEnd = () => {
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
      element.removeEventListener('touchcancel', handleTouchEnd);
    };
    
    element.addEventListener('touchmove', handleTouchMove, { passive: false });
    element.addEventListener('touchend', handleTouchEnd);
    element.addEventListener('touchcancel', handleTouchEnd);
  }, [lockedField, handleDataSizeWheel]);

  // Add this new function after handleTouchStart
  const getDataSizeInputClass = () => {
    if (isMobile) {
      return `${commonStyles.commonInputClass} relative after:content-['×10'] after:absolute after:left-4 
      after:top-1/2 after:-translate-y-1/2 after:text-gray-400 after:pointer-events-none 
      after:opacity-70 after:text-sm after:bg-black/20 after:px-2 after:py-1 after:rounded 
      before:content-['×1'] before:absolute before:right-4 before:top-1/2 
      before:-translate-y-1/2 before:text-gray-400 before:pointer-events-none 
      before:opacity-70 before:text-sm before:bg-black/20 before:px-2 before:py-1 before:rounded`;
    }
    return commonStyles.commonInputClass;
  };

  // Update handleShare to use native share when available
  const handleShare = async () => {
    const shareUrl = new URL(window.location.href);
    shareUrl.searchParams.set('interface', selectedInterface.name);
    shareUrl.searchParams.set('sourceStorage', sourceStorage.name);
    shareUrl.searchParams.set('destStorage', destStorage.name);
    shareUrl.searchParams.set('dataSize', dataSize);

    const shareText = `${dataSize} transfer via ${selectedInterface.name}\n` +
                     `From: ${sourceStorage.name} (${sourceStorage.speed} Gbps)\n` +
                     `To: ${destStorage.name} (${destStorage.speed} Gbps)\n` +
                     `Time: ${transferTime}`;
    
    try {
      const result = await handleShareOnDevice(
        'TBD Post DataCalc',
        `Check out this transfer time calculation: ${shareText}`,
        shareUrl.toString()
      );

      if (result?.success) {
        setShareButtonText(<SlLike />);
        setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
      }
      return result;
    } catch (error) {
      console.error('Share failed:', error);
      return { success: false, method: 'none' };
    }
  };

  const handleLogClick = () => {
    if (addLog) {
      addLog(`${dataSize} via ${selectedInterface.name} to ${destStorage.name}: ${transferTime}`);
    }
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);
  };

  const handleReset = () => {
    setSelectedInterface(interfaces[0]);
    setSourceStorage(storageDevices[0]);
    setDestStorage(storageDevices[0]);
    setDataSize('1.00 TB');
    setLockedField(null);
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  // Add these handlers after the other handlers

  const handleDataSizeFocus = () => {
    if (!lockedField) {
      setDataSize('');
    }
  };

  const handleDataSizeBlur = () => {
    if (dataSize.trim() === '') {
      setDataSize('');
      return;
    }

    const parsed = parseFileSize(dataSize);
    if (parsed) {
      const formattedSize = `${parsed.size} ${parsed.unit}`;
      setDataSize(formattedSize);
      calculateTransferTime();
    }
  };

  const handleDataSizeKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  // Add useEffect for mobile scroll prevention
  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isMobile]);

  return (
    <div className={`transfer-calculator ${currentSpacing.padding} ${colors.bgColor} ${colors.textColor}`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Source Storage Device selection */}
        <div className="order-1">
          <label className={`${commonStyles.labelClass} md:text-base`}>Source Storage</label>
          <select
            value={sourceStorage.name}
            onChange={(e) => setSourceStorage(storageDevices.find(s => s.name === e.target.value) || storageDevices[0])}
            onWheel={handleSourceStorageWheel}
            className={commonStyles.commonInputClass}
            disabled={lockedField === 'sourceStorage'}
          >
            {storageDevices.map((s) => (
              <option key={s.name} value={s.name}>
                {s.name} ({s.speed} Gbps)
              </option>
            ))}
          </select>
        </div>

        {/* Interface selection */}
        <div className="order-2">
          <label className={`${commonStyles.labelClass} md:text-base`}>Interface</label>
          <select
            ref={interfaceRef}
            value={selectedInterface.name}
            onChange={(e) => setSelectedInterface(interfaces.find(i => i.name === e.target.value) || interfaces[0])}
            onWheel={handleInterfaceWheel}
            className={commonStyles.commonInputClass}
            disabled={lockedField === 'interface'}
          >
            {interfaces.map((i) => (
              <option key={i.name} value={i.name}>
                {i.name} ({i.speed} Gbps)
              </option>
            ))}
          </select>
        </div>

        {/* Destination Storage Device selection */}
        <div className="order-3">
          <label className={`${commonStyles.labelClass} md:text-base`}>Destination Storage</label>
          <select
            value={destStorage.name}
            onChange={(e) => setDestStorage(storageDevices.find(s => s.name === e.target.value) || storageDevices[0])}
            onWheel={handleDestStorageWheel}
            className={commonStyles.commonInputClass}
            disabled={lockedField === 'destStorage'}
          >
            {storageDevices.map((s) => (
              <option key={s.name} value={s.name}>
                {s.name} ({s.speed} Gbps)
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Data Size and Transfer Time - now in a separate div */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        {/* Data size input */}
        <div className="md:col-span-1">
          <label className={commonStyles.labelClass}>Data Size</label>
          <input
            ref={dataSizeRef}
            type="text"
            value={dataSize}
            onChange={(e) => setDataSize(e.target.value)}
            onFocus={handleDataSizeFocus}
            onBlur={handleDataSizeBlur}
            onKeyPress={handleDataSizeKeyPress}
            onWheel={handleDataSizeWheel}
            onTouchStart={(e) => handleTouchStart(e, 'dataSize')}
            placeholder={lockedField === 'dataSize' ? '' : "ex: 5 TB or 500GB"}
            className={getDataSizeInputClass()}
            disabled={lockedField === 'dataSize'}
          />
        </div>

        {/* Transfer time display */}
        <div className="md:col-span-2">
          <label className={`${commonStyles.labelClass} md:text-base`}>Est. Time</label>
          <div className={`${commonStyles.commonInputClass} ${colors.resultText}`}>
            {transferTime}
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <CalculatorButtons 
        onLog={handleLogClick}
        onShare={handleShare}
        onReset={handleReset}
        text={<span className="font-bold">{`${dataSize} transfer time: ${transferTime}`}</span>}
        logButtonIcon={logButtonText}
        shareButtonIcon={shareButtonText}
        resetButtonIcon={resetButtonText}
      />
    </div>
  );
};

export default TransferTimeCalculator; 