import { AppleCameras } from "./cameraData/cameraDataApple";
import { ARRICameras } from "./cameraData/cameraDataArri";
import { BlackmagicCameras } from "./cameraData/cameraDataBlackmagic";
import { CanonCameras } from "./cameraData/cameraDataCanon";
import { DJICameras } from "./cameraData/cameraDataDJI";
import { FreeflyCameras } from "./cameraData/cameraDataFreefly";
import { FujifilmCameras } from "./cameraData/cameraDataFujifilm";
import { GoogleCameras } from "./cameraData/cameraDataGoogle";
import { GoProCameras } from "./cameraData/cameraDataGoPro";
import { LeicaCameras } from "./cameraData/cameraDataLeica";
import { KinefinityCameras } from "./cameraData/cameraDataKinefinity";
import { NikonCameras } from "./cameraData/cameraDataNikon";
import { PanasonicCameras } from "./cameraData/cameraDataPanasonic";
import { PanavisionCameras } from "./cameraData/cameraDataPanavision";
import { PhantomCameras } from "./cameraData/cameraDataPhantom";
import { RedCameras } from "./cameraData/cameraDataRed";
import { SamsungCameras } from "./cameraData/cameraDataSamsung";
import { SigmaCameras } from "./cameraData/cameraDataSigma";
import { SonyCameras } from "./cameraData/cameraDataSony";
import { ZCamCameras } from "./cameraData/cameraDataZCam";

export const cameras = {
  ...AppleCameras,
  ...ARRICameras,
  ...BlackmagicCameras,
  ...CanonCameras,
  ...DJICameras,
  ...FreeflyCameras,
  // ...FujifilmCameras,
  // ...GoogleCameras,
  ...GoProCameras,
  // ...LeicaCameras,
  // ...KinefinityCameras,
  // ...NikonCameras,
  // ...PanasonicCameras,
  // ...PanavisionCameras,
  ...PhantomCameras,
  ...RedCameras,
  // ...SamsungCameras,
  // ...SigmaCameras,
  ...SonyCameras,
  ...ZCamCameras,
};

export const getFrameratesForCamera = (cameraName, resolution, codec) => {
  const camera = cameras[cameraName];
  if (!camera) {
    console.error(`Camera not found: ${cameraName}`);
    return [];
  }

  if (!resolution || !codec) {
    // If no resolution or codec is specified, return all unique framerates for this camera
    return [...new Set(Object.values(camera.resolutions)
      .flatMap(res => Object.values(res.codecs)
        .flatMap(cod => cod.framerates)))].sort((a, b) => parseFloat(a) - parseFloat(b));
  }

  const resolutionData = camera.resolutions[resolution];
  if (!resolutionData) {
    console.error(`Resolution not found for camera ${cameraName}: ${resolution}`);
    return [];
  }

  const codecData = resolutionData.codecs[codec];
  if (!codecData) {
    console.error(`Codec not found for camera ${cameraName} and resolution ${resolution}: ${codec}`);
    return [];
  }

  return codecData.framerates.sort((a, b) => parseFloat(a) - parseFloat(b));
};

// Implementation of getFrameratesForCameraResolutionCodec
const getFrameratesForCameraResolutionCodec = (camera, resolution, codec) => {
  if (!cameras[camera]) {
    console.error(`Camera not found: ${camera}`);
    return [];
  }

  const cameraData = cameras[camera];
  if (!cameraData.resolutions[resolution]) {
    console.error(`Resolution not found for camera ${camera}: ${resolution}`);
    return [];
  }

  const resolutionData = cameraData.resolutions[resolution];
  if (!resolutionData.codecs[codec]) {
    console.error(`Codec not found for camera ${camera} and resolution ${resolution}: ${codec}`);
    return [];
  }

  return resolutionData.codecs[codec].framerates;
};

// Add a helper function to get cameras by brand
export const getCamerasByBrand = (brand) => {
  let brandCameras = [];
  switch (brand) {
    case 'Apple':
      brandCameras = Object.keys(AppleCameras);
      break;
    case 'ARRI':
      brandCameras = Object.keys(ARRICameras);
      break;
    case 'Blackmagic Design':
      brandCameras = Object.keys(BlackmagicCameras);
      break;
    case 'Canon':
      brandCameras = Object.keys(CanonCameras);
      break;
    case 'DJI':
      brandCameras = Object.keys(DJICameras);
      break;
    case 'Freefly':
      brandCameras = Object.keys(FreeflyCameras);
      break;
    case 'GoPro':
      brandCameras = Object.keys(GoProCameras);
      break;
    case 'Phantom':
      brandCameras = Object.keys(PhantomCameras);
      break;
    case 'RED':
      brandCameras = Object.keys(RedCameras);
      break;
    case 'Sony':
      brandCameras = Object.keys(SonyCameras);
      break;
    case 'Z CAM':
      brandCameras = Object.keys(ZCamCameras);
      break;
    default:
      brandCameras = [];
  }
  // Sort the cameras alphabetically before returning
  return brandCameras.sort((a, b) => a.localeCompare(b));
};

// Add a helper function to get resolutions for a specific camera
export const getResolutionsForCamera = (camera) => {
    return cameras[camera] ? Object.keys(cameras[camera].resolutions) : [];
};

// Add a helper function to get codecs for a specific camera and resolution
export const getCodecsForCameraAndResolution = (camera, resolution) => {
    return cameras[camera] && cameras[camera].resolutions[resolution]
        ? Object.keys(cameras[camera].resolutions[resolution].codecs)
        : [];
};
