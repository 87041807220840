import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SlDocs, SlMinus, SlLike, SlPaperPlane } from "react-icons/sl";
import { FaShareSquare, FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCalculatorName } from '../config/features';

const Log = forwardRef(({ log = [], onDelete, onShare, isMobile, previousCalculator }, ref) => {
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [sharedIndices, setSharedIndices] = useState({});
  const logContainerRef = useRef(null);
  const isFullPage = window.location.pathname === '/logs';
  const navigate = useNavigate();
  const location = useLocation();

  // Get the display name for the previous calculator
  const getPreviousCalculatorName = () => {
    if (location.state?.calculatorName) {
      return location.state.calculatorName;
    }
    if (location.state?.from) {
      return getCalculatorName(location.state.from);
    }
    return getCalculatorName(previousCalculator) || 'Calculator';
  };

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (logContainerRef.current) {
        logContainerRef.current.scrollTop = 0;
      }
    }
  }));

  const handleShareLog = (entry, index) => {
    const shareUrl = window.location.href;
    const title = 'Calculator Log Entry';
    
    if (isMobile && navigator.share) {
      navigator.share({
        title,
        text: entry,
        url: shareUrl
      })
        .then(() => {
          setSharedIndices(prev => ({ ...prev, [index]: true }));
          setTimeout(() => setSharedIndices(prev => ({ ...prev, [index]: false })), 500);
        })
        .catch(err => console.error('Error sharing:', err));
    } else {
      navigator.clipboard.writeText(entry)
        .then(() => {
          setSharedIndices(prev => ({ ...prev, [index]: true }));
          setTimeout(() => setSharedIndices(prev => ({ ...prev, [index]: false })), 500);
        })
        .catch(err => console.error('Error copying to clipboard:', err));
    }
  };

  const handleBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  if (!log || log.length === 0) {
    return (
      <div ref={logContainerRef} className={`mt-0 overflow-y-auto ${
        isFullPage 
          ? 'h-[calc(100vh-200px)]' 
          : 'h-[calc(100vh-50px)]'
      } scrollbar-hide`}>
        <div className="bg-white rounded p-3 mb-2 group shadow-md">
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-500 flex-grow pr-4">No entries yet</span>
            <div className="flex space-x-2">
              {/* <button disabled className="px-2 py-2 text-xs bg-gray-300 text-white rounded">
                <SlDocs />
              </button> */}
              <button disabled className="px-2 py-2 text-xs bg-gray-300 text-white rounded">
                <SlPaperPlane />
              </button>
              <button disabled className="px-2 py-2 text-xs bg-gray-300 text-white rounded">
                <SlMinus />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      ref={logContainerRef} 
      className={`overflow-y-auto ${
        isFullPage 
          ? 'h-[calc(100vh-200px)]' 
          : 'h-[calc(100vh-50px)]'
      } scrollbar-hide`}
    >
      {log.map((entry, index) => (
        <div key={index} className={`bg-white p-3 mb-2 group shadow-md rounded text-left`}>
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-900 flex-grow pr-4 whitespace-pre-line">{entry}</p>
            <div className="flex space-x-2 flex-shrink-0">
              {/* <CopyToClipboard text={entry} onCopy={() => {
                setCopiedIndex(index);
                setTimeout(() => setCopiedIndex(null), 500);
              }}>
                <button className="px-2 py-2 text-xs bg-blue-500 text-white rounded hover:bg-blue-600">
                  {copiedIndex === index ? <SlLike /> : <SlDocs />}
                </button>
              </CopyToClipboard> */}
              {(isMobile || true) && (
                <button
                  onClick={() => handleShareLog(entry, index)}
                  className="px-2 py-2 text-xs bg-blue-600 text-white rounded hover:bg-blue-500"
                >
                  {sharedIndices[index] ? <SlLike /> : <SlPaperPlane />}
                </button>
              )}
              <button
                onClick={() => onDelete(index)}
                className="px-2 py-2 text-xs bg-red-600 text-white rounded hover:bg-red-500"
              >
                <SlMinus />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
});

Log.displayName = 'Log';

export default Log;