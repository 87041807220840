export const netflixTemplates = {
  "Netflix Episodic": {
    name: "Netflix Episodic",
    template: "[PROJECT_TITLE]_[SEASON_EPISODE]_[ASSET_TYPE]_[BIT_DEPTH]_[DYNAMIC_RANGE]_[ACES_VERSION]_[COLOR_SPACE]_[TRANSFER_FUNCTION]_[DATE]_[ASPECT_RATIO]_[RESOLUTION]",
    folderStructure: "[PROJECT_TITLE]/[SEASON_EPISODE]/[ASSET_TYPE]/",
    requiredTags: ['PROJECT_TITLE', 'SEASON_EPISODE', 'ASSET_TYPE', 'BIT_DEPTH', 'DYNAMIC_RANGE', 'ACES_VERSION', 'COLOR_SPACE', 'TRANSFER_FUNCTION', 'DATE', 'ASPECT_RATIO', 'RESOLUTION', 'EXT']
  },
  "Netflix Non-Episodic Longplay": {
    name: "Netflix Non-Episodic Longplay",
    template: "[PROJECT_TITLE]_[SEGMENT]_[ASSET_TYPE]_[BIT_DEPTH]_[DYNAMIC_RANGE]_[ACES_VERSION]_[COLOR_SPACE]_[TRANSFER_FUNCTION]_[DATE]_[ASPECT_RATIO]_[RESOLUTION]",
    folderStructure: "[PROJECT_TITLE]/[ASSET_TYPE]/",
    requiredTags: ['PROJECT_TITLE', 'SEGMENT', 'ASSET_TYPE', 'BIT_DEPTH', 'DYNAMIC_RANGE', 'ACES_VERSION', 'COLOR_SPACE', 'TRANSFER_FUNCTION', 'DATE', 'ASPECT_RATIO', 'RESOLUTION', 'EXT']
  },
  "Netflix Non-Episodic Segmented": {
    name: "Netflix Non-Episodic Segmented",
    template: "[PROJECT_TITLE]_[SEGMENT]_[ASSET_TYPE]_[BIT_DEPTH]_[DYNAMIC_RANGE]_[ACES_VERSION]_[COLOR_SPACE]_[TRANSFER_FUNCTION]_[DATE]_[ASPECT_RATIO]_[RESOLUTION]",
    folderStructure: "[PROJECT_TITLE]/[ASSET_TYPE]/[SEGMENT]/",
    requiredTags: ['PROJECT_TITLE', 'SEGMENT', 'ASSET_TYPE', 'BIT_DEPTH', 'DYNAMIC_RANGE', 'ACES_VERSION', 'COLOR_SPACE', 'TRANSFER_FUNCTION', 'DATE', 'ASPECT_RATIO', 'RESOLUTION', 'EXT']
  }
};

export const netflixFields = {
  PROJECT_TITLE: {
    type: 'text',
    label: 'Project Title',
    placeholder: 'PROJECT_TITLE',
    pattern: '[A-Z0-9_-]+',
    help: 'All caps, substitute spaces with underscores',
    width: 2 // Takes up 2 columns
  },
  SEASON_EPISODE: {
    type: 'text',
    label: 'Season & Episode',
    placeholder: 'S01E01',
    pattern: 'S[0-9]{2}E[0-9]{2}',
    help: 'Format: S##E## (e.g., S01E01)',
    width: 1
  },
  SEGMENT: {
    type: 'select',
    label: 'Segment',
    options: ['LP', ...Array.from({length: 20}, (_, i) => `R${String(i + 1).padStart(2, '0')}`)],
    help: 'LP for longplay, R## for segments/reels',
    width: 1
  },
  ASSET_TYPE: {
    type: 'select',
    label: 'Asset Type',
    options: ['nam', 'nam-txtls', 'dcdm', 'dcdm-txtls'],
    help: 'NAM (Non-Graded Archival Master), DCDM (Digital Cinema Distribution Master)',
    width: 1
  },
  BIT_DEPTH: {
    type: 'select',
    label: 'Bit Depth',
    options: ['10b', '16b'],
    help: 'Format: ##b (e.g., 16b)',
    width: 1
  },
  DYNAMIC_RANGE: {
    type: 'select',
    label: 'Dynamic Range',
    options: ['hdr', 'sdr'],
    help: 'HDR (High Dynamic Range), SDR (Standard Dynamic Range)',
    width: 1
  },
  ACES_VERSION: {
    type: 'select',
    label: 'ACES Version',
    options: ['aces110', 'aces103'],
    help: 'Format: aces### (e.g., aces110 for ACES 1.1)',
    width: 1
  },
  COLOR_SPACE: {
    type: 'select',
    label: 'Color Space',
    options: [
      'ap0', 'adx', 'arriwg', 'bmdfilm', 'bmdfilm46k', 'canonp3dci', 'canoncine',
      'cfcolor', 'cineonpd', 'dwg', 'p3dci', 'dragonc', 'dragonc2', 'egamut',
      'rec2020', 'rec709', 'p3d65', 'vgamut', 'redc', 'redc2', 'redc3', 'redc4',
      'redspace', 'rwg', 'sgamut', 'sgamut3', 'sgamut3-cine', 'srgb', 'xyz'
    ],
    help: 'Color space abbreviation',
    width: 1
  },
  TRANSFER_FUNCTION: {
    type: 'select',
    label: 'Transfer Function',
    options: [
      'ap0', 'adx', 'bmdlog', 'bmdlog46k', 'canonlog2', 'canonlog3', 'cflog',
      'cineon', 'dint', 'g26', 'tlog', 'g24', 'lin', 'logc', 'vlog', 'log3g10',
      'redlogfilm', 'slog2', 'slog3', 'pq', 'g22'
    ],
    help: 'Transfer function abbreviation',
    width: 1
  },
  DATE: {
    type: 'text',
    label: 'Date',
    placeholder: 'YYYYMMDD',
    pattern: '[0-9]{8}',
    help: 'Format: YYYYMMDD',
    width: 1,
    autoFill: true
  },
  ASPECT_RATIO: {
    type: 'text',
    label: 'Aspect Ratio',
    placeholder: '178',
    pattern: '[0-9]{3}',
    help: 'Format: ### (e.g., 178 for 1.78:1)',
    width: 1
  },
  RESOLUTION: {
    type: 'select',
    label: 'Resolution',
    options: ['1920x1080', '2048x1080', '3840x2160', '4096x2160'],
    help: 'Format: ####x#### (full resolution)',
    width: 1
  },
  EXT: {
    type: 'select',
    label: 'Extension',
    options: ['dpx', 'exr', 'tif'],
    help: 'File extension without dot',
    width: 1
  }
}; 