// Film formats (simplified without aspect ratios)
export const filmFormats = [
  "Super 8mm",
  "16mm / Super 16mm",  // Combined format
  "35mm 2-perf",
  "35mm 3-perf",
  "35mm 4-perf",
  "65mm 5-perf",
  "70mm 15-perf"
];

// Define stocks once per format
export const filmStocks = {
  "8mm": [
    "7219 VISION3 500T Color Neg",
    "7213 VISION3 200T Color Neg",
    "7203 VISION3 50D Color Neg",
    "7294 EKTACHROME 100D Color Rev",
    "7266 TRI-X B&W Rev"
  ],
  
  "16mm": [
    "7219 VISION3 500T Color Neg",
    "7213 VISION3 200T Color Neg",
    "7207 VISION3 250D Color Neg",
    "7203 VISION3 50D Color Neg",
    "7294 EKTACHROME 100D Color Rev",
    "7266 TRI-X B&W Rev",
    "7222 DOUBLE-X B&W Neg"
  ],

  "35mm": [
    "5219 VISION3 500T Color Neg",
    "5213 VISION3 200T Color Neg",
    "5207 VISION3 250D Color Neg",
    "5203 VISION3 50D Color Neg",
    "5294 EKTACHROME 100D Color Rev",
    "5222 DOUBLE-X B&W Neg"
  ],

  "65mm": [
    "5219 VISION3 500T Color Neg",
    "5213 VISION3 200T Color Neg",
    "5207 VISION3 250D Color Neg",
    "5203 VISION3 50D Color Neg"
  ]
};

// Define available lengths and prices for each stock
export const stockOptions = {
  "7219 VISION3 500T Color Neg": {
      "8mm": [
        { length: "50", price: "31.99" }
      ],
      "16mm": [
        { length: "100", price: "55.00" },
        { length: "400", price: "220.00" },
        { length: "400 (2R)", price: "250.80" }
    ]
  },
  "5219 VISION3 500T Color Neg": {
    "35mm": [
      { length: "200", price: "172.25" },
      { length: "400", price: "316.56" },
      { length: "1000", price: "791.40" }
    ],
    "65mm": [
      { length: "400", price: "618.80" },
      { length: "500", price: "773.50" },
      { length: "1000", price: "1509.27" }
    ]
  },
  "7207 VISION3 250D Color Neg": {
    "16mm": [
      { length: "100", price: "55.00" },
      { length: "400", price: "220.00" }
    ],
  },
  "5207 VISION3 250D Color Neg": {
    "35mm": [
      { length: "200", price: "172.25" },
      { length: "400", price: "316.56" },
      { length: "1000", price: "791.40" }
    ],
    "65mm": [
      { length: "400", price: "618.80" },
      { length: "500", price: "773.50" },
      { length: "1000", price: "1509.27" }
    ]
  },
  "7213 VISION3 200T Color Neg": {
    "8mm": [
      { length: "50", price: "31.99" }
    ],
    "16mm": [
      { length: "100", price: "55.00" },
      { length: "400", price: "220.00" }
    ],
},
"5213 VISION3 200T Color Neg": {
    "35mm": [
      { length: "400", price: "316.59" },
      { length: "1000", price: "791.40" }
    ],
    "65mm": [
      { length: "500", price: "773.50" },
      { length: "1000", price: "1509.27" }
    ]
  },
  "7203 VISION3 50D Color Neg": {
    "8mm": [
      { length: "50", price: "31.99" }
    ],
    "16mm": [
      { length: "100", price: "55.00" },
      { length: "400", price: "220.00" }
    ],
},
"5203 VISION3 50D Color Neg": {
    "35mm": [
      { length: "400", price: "316.56" },
      { length: "1000", price: "791.40" }
    ],
    "65mm": [
      { length: "500", price: "773.50" },
      { length: "1000", price: "1509.27" }
    ]
  },
  "7294 EKTACHROME 100D Color Rev": {
    "8mm": [
      { length: "50", price: "42.99" }
    ],
    "16mm": [
      { length: "100", price: "65.00" },
      { length: "400", price: "260.00" },
      { length: "400 (2R)", price: "296.31" }
    ],
  },
  "5294 EKTACHROME 100D Color Rev": {
    "35mm": [
      { length: "400", price: "520.00" },
      { length: "1000", price: "1300.00" }
    ]
  },
  "7222 DOUBLE-X B&W Neg": {
    "16mm": [
      { length: "100", price: "55.00" },
      { length: "400", price: "220.00" }
    ],
  },
  "5222 DOUBLE-X B&W Neg": {
    "35mm": [
      { length: "400", price: "316.56" },
      { length: "1000", price: "791.40" }
    ]
  },
  "7266 TRI-X B&W Rev": {
    "8mm": [
      { length: "50", price: "31.99" }
    ],
    "16mm": [
      { length: "100", price: "55.00" },
      { length: "400", price: "220.00" }
    ]
  }
};

// Frames per foot for each format
export const framesPerFoot = {
  "Super 8mm": 72,
  "16mm / Super 16mm": 40,
  "35mm 2-perf": 32,
  "35mm 3-perf": 21.33,
  "35mm 4-perf": 16,
  "65mm 5-perf": 12.8,
  "70mm 15-perf": 4.3
};

// Helper functions
export const getBaseFormat = (format) => {
  const formatMap = {
    "Super 8mm": "8mm",
    "16mm / Super 16mm": "16mm",
    "35mm 2-perf": "35mm",
    "35mm 3-perf": "35mm",
    "35mm 4-perf": "35mm",
    "65mm 5-perf": "65mm",
    "70mm 15-perf": "65mm"
  };
  return formatMap[format] || format;
};

export const getStockLengths = (stockName, format) => {
  const baseFormat = getBaseFormat(format);
  console.log('Getting lengths for:', { stockName, format, baseFormat }); // Debug log
  const lengths = stockOptions[stockName]?.[baseFormat] || [];
  console.log('Available lengths:', lengths); // Debug log
  return lengths;
};

export const getStockPrice = (stockName, length, format) => {
  const baseFormat = getBaseFormat(format);
  const lengths = stockOptions[stockName]?.[baseFormat] || [];
  return lengths.find(option => option.length === length)?.price;
};
