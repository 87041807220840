import React from 'react';

const PrivacyPolicy = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="relative bg-white rounded w-full max-w-2xl max-h-[90vh] overflow-y-auto p-6">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 z-10"
        >
          ✕
        </button>

        <h2 className="text-xl font-bold mb-4 text-center pr-8">Privacy Policy</h2>
        
        <h3 className="text-med font-semibold mt-4 1">Information We Collect</h3>
        <p className="text-sm mb-4">We collect minimal, non-identifiable information to enhance your experience and improve our Service. The types of information we may collect include:</p>
        <ul className="list-disc list-inside mb-4">
          <li className="text-sm">Usage Data: Information about how you interact with our Service, such as pages visited and features used.</li>
          <li className="text-sm">Technical Data: Non-identifiable data like your IP address, browser type, operating system, and device information.</li>
        </ul>

        <h3 className="text-med font-semibold mt-4 1">How We Use Your Information</h3>
        <p className="text-sm mb-4">We use the collected information for the following purposes:</p>
        <ul className="list-disc list-inside mb-4">
          <li className="text-sm">To operate and maintain our Service.</li>
          <li className="text-sm">To improve user experience and functionality.</li>
          <li className="text-sm">To analyze usage patterns to enhance our Service.</li>
        </ul>

        <h3 className="text-med font-semibold mt-4 1">Legal Basis for Processing (For EU Users)</h3>
        <p className="text-sm mb-4">Under the General Data Protection Regulation (GDPR), our legal bases for processing your information are:</p>
        <p className="text-sm mb-4">Legitimate Interests: We process non-identifiable information to improve our Service.</p>

        <h3 className="text-med font-semibold mt-4 1">Cookies and Similar Technologies</h3>
        <p className="text-sm mb-4">We may use cookies and similar tracking technologies to collect and store information. Cookies are small data files placed on your device. You can instruct your browser to refuse all cookies or indicate when a cookie is being sent.</p>

        <h3 className="text-med font-semibold mt-4 1">Data Retention</h3>
        <p className="text-sm mb-4">We retain collected information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

        <h3 className="text-med font-semibold mt-4 1">Data Security</h3>
        <p className="text-sm mb-4">We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction.</p>

        <h3 className="text-med font-semibold mt-4 1">Your Rights</h3>
        <p className="text-sm mb-4">EU/EEA Residents</p>
        <p className="text-sm mb-4">Under the GDPR, you have the following rights:</p>
        <ul className="list-disc list-inside mb-4">
          <li className="text-sm">Access: Request access to your personal data.</li>
          <li className="text-sm">Rectification: Request correction of any inaccurate data.</li>
          <li className="text-sm">Erasure: Request deletion of your personal data.</li>
          <li className="text-sm">Restriction: Request restriction of processing your data.</li>
          <li className="text-sm">Objection: Object to processing based on legitimate interests.</li>
          <li className="text-sm">Data Portability: Request transfer of your data to you or a third party.</li>
        </ul>
        <p className="text-sm mb-4">California Residents</p>
        <p className="text-sm mb-4">Under the California Consumer Privacy Act (CCPA), you have the right to:</p>
        <ul className="list-disc list-inside mb-4">
          <li className="text-sm">Know: Request disclosure of the categories and specific pieces of personal information collected.</li>
          <li className="text-sm">Delete: Request deletion of personal information we have collected.</li>
          <li className="text-sm">Non-Discrimination: Not be discriminated against for exercising your privacy rights.</li>
        </ul>
        <p className="text-sm mb-4">Note: Since we do not collect personal information that identifies you individually, certain rights may not be applicable.</p>

        <h3 className="text-med font-semibold mt-4 1">Children's Privacy</h3>
        <p className="text-sm mb-4">Our Service is not intended for individuals under the age of 16. We do not knowingly collect personal information from children.</p>

        <h3 className="text-med font-semibold mt-4 1">International Data Transfers</h3>
        <p className="text-sm mb-4">Your information may be transferred to and processed in countries other than your country of residence. We ensure that appropriate safeguards are in place to protect your data.</p>

        <h3 className="text-med font-semibold mt-4 1">Changes to This Privacy Policy</h3>
        <p className="text-sm mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

        <h3 className="text-med font-semibold mt-4 1">Trademark Disclaimer</h3>
        <p className="text-sm mb-4">
          All names, brand names, logos, and trademarks used on this website are the property of their respective owners. We do not claim any rights, title, or interest in or to any of these trademarks.
        </p>

        <h3 className="text-med font-semibold mt-4 1">Contact Us</h3>
        <p className="text-sm mb-4">If you have any questions or concerns about these policies or our data practices, please contact us:</p>
        <p className="text-sm">TBD Post</p>
        <p className="text-sm">2815 Manor Rd, Ste 202</p>
        <p className="text-sm">Austin, TX 78722 USA</p>
        <p className="text-sm"><a href="mailto:datacalc@tbdpost.com">datacalc@tbdpost.com</a></p>

        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mx-auto block md:block hidden">
          Close
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
