// Interface speed data
export const interfaces = [
  // USB
  { name: 'USB4', speed: 40 },
  { name: 'USB 3.2 Gen 2x2', speed: 20 },
  { name: 'USB 3.2 Gen 2', speed: 10 },
  { name: 'USB 3.1/3.2 Gen 1', speed: 5 },
  { name: 'USB 2.0', speed: 0.48 },
  { name: 'USB 1.1', speed: 0.012 },
    
  // Thunderbolt
  { name: 'Thunderbolt 5', speed: 120 },
  { name: 'Thunderbolt 4', speed: 40 },
  { name: 'Thunderbolt 3', speed: 40 },
  { name: 'Thunderbolt 2', speed: 20 },
  { name: 'Thunderbolt 1', speed: 10 },

  // Wi-Fi
  { name: 'Wi-Fi (802.11ax)', speed: 9.6 },
  { name: 'Wi-Fi (802.11ac)', speed: 3.5 },
  { name: 'Wi-Fi (802.11n)', speed: 0.6 },
  { name: 'Wi-Fi (802.11g)', speed: 0.3 },
  { name: 'Wi-Fi (802.11b)', speed: 0.11 },
  { name: 'Wi-Fi (802.11a)', speed: 0.06 },

  // Ethernet
  { name: 'Ethernet 100G', speed: 100 },
  { name: 'Ethernet 40G', speed: 40 },
  { name: 'Ethernet 25G', speed: 25 },
  { name: 'Ethernet 10G', speed: 10 },
  { name: 'Ethernet 1G', speed: 1 },
  { name: 'Ethernet 500 Mbps', speed: 0.5 },
  { name: 'Ethernet 300 Mbps', speed: 0.3 },
  { name: 'Ethernet 100 Mbps', speed: 0.1 },

  // PCIe
  { name: 'PCIe 5.0 x16', speed: 64 },
  { name: 'PCIe 5.0 x4', speed: 16 },
  { name: 'PCIe 5.0 x8', speed: 32 },
  { name: 'PCIe 4.0 x16', speed: 32 },
  { name: 'PCIe 4.0 x8', speed: 16 },
  { name: 'PCIe 4.0 x4', speed: 8 },
  { name: 'PCIe 3.0 x16', speed: 16 },
  { name: 'PCIe 3.0 x8', speed: 8 },
  { name: 'PCIe 3.0 x4', speed: 4 },
  { name: 'PCIe 3.0 x2', speed: 2 },

  // SATA
  { name: 'SATA III', speed: 0.6 },
  { name: 'SATA II', speed: 0.3 },
  { name: 'SATA I', speed: 0.15 },

  // FireWire
  { name: 'FireWire 800', speed: 0.8 },
  { name: 'FireWire 400', speed: 0.4 }
];

// Storage device speed data
export const storageDevices = [
  // SSDs
  { name: 'NVMe SSD (PCIe 5.0)', speed: 14 },
  { name: 'NVMe SSD (PCIe 4.0)', speed: 7 },
  { name: 'NVMe SSD (PCIe 3.0)', speed: 3.5 },
  { name: 'SATA SSD (High-end)', speed: 0.6 },
  { name: 'SATA SSD (Budget)', speed: 0.4 },

  // HDDs
  { name: 'HDD (15,000 RPM)', speed: 0.3 },
  { name: 'HDD (7200 RPM)', speed: 0.15 },
//   { name: 'Consumer HDD (5400 RPM)', speed: 0.1 },

//   // RAID Arrays
//   { name: 'RAID 0 (4x NVMe SSD)', speed: 56 },
//   { name: 'RAID 0 (4x SATA SSD)', speed: 2.4 },
//   { name: 'RAID 0 (4x 7200 RPM HDD)', speed: 0.6 },

  // Memory Cards
  { name: 'CFexpress Type B', speed: 2 },
  { name: 'SD Card (UHS-III)', speed: 0.624 },
  { name: 'SD Card (UHS-II)', speed: 0.312 },
  { name: 'SD Card (UHS-I)', speed: 0.104 },

  // Tape Drives
  { name: 'LTO-9 Tape Drive', speed: 0.4 },
  { name: 'LTO-8 Tape Drive', speed: 0.36 },
  { name: 'LTO-7 Tape Drive', speed: 0.3 },
  { name: 'LTO-6 Tape Drive', speed: 0.2 },
  { name: 'LTO-5 Tape Drive', speed: 0.15 },

  // External Drives
  { name: 'Thunderbolt 3 SSD', speed: 2.5 },
  { name: 'USB 3.2 SSD', speed: 0.5 },
  { name: 'USB 3.2 HDD', speed: 0.12 }
]; 