import React, { useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { 
  FaClock, 
  FaClipboardList,
  FaPlayCircle 
} from 'react-icons/fa';
import { FaVideo } from 'react-icons/fa6';
import { BsFillAspectRatioFill } from 'react-icons/bs';
import { PiFilmReelFill } from 'react-icons/pi';
import { BiTransfer } from 'react-icons/bi';
import { MdInsertChart } from 'react-icons/md';
import { RiFileEditFill } from 'react-icons/ri';
import { shouldShowInHeader, calculatorConfig, isInBetaEnvironment } from '../config/features';
import { headerStyles, breakpoints } from '../styles/globalStyles';
import { handleShareOnDevice } from '../utils/shareUtils';

function Header({ isMobile }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef(null);
  
  const menuItems = Object.entries(calculatorConfig).map(([id, calc]) => ({
    id,
    path: calc.path,
    label: calc.headerMenu,
    icon: calc.icon
  }));

  // Add safety check when filtering
  const filteredMenuItems = menuItems.filter(item => {
    const calculator = calculatorConfig[item.id];
    return calculator ? shouldShowInHeader(calculator) : true;
  });

  // Use filteredMenuItems for both dropdown and mobile icons
  const mobileMenuItems = filteredMenuItems.filter(item => 
    !['/comingsoon'].includes(item.path)
  );

  const handleCalculatorChange = (e) => {
    const value = e.target.value;
    navigate(value);
  };

  const getGridColumns = (itemCount) => {
    return `grid-cols-${itemCount}`;
  };

  const formatMenuLabel = (calculator) => {
    const isBeta = calculator?.beta && !calculator?.comingSoon;
    const isComingSoon = calculator?.comingSoon;
    const inBeta = isInBetaEnvironment();
    
    let label = calculator.headerMenu;
    
    if (isBeta) {
      label = `${label} (BETA)`;
    }
    
    if (inBeta && isComingSoon) {
      label = `${label} (SOON)`;
    }
    
    return label;
  };

  // Update the isCompactView check to only affect mobile
  const isCompactView = isMobile;

  return (
    <header className={`bg-white rounded shadow-md ${
      isCompactView ? 'p-2' : 'p-4'
    }`}>
      <div className="flex h-full">
        {/* Logo Column */}
        <div className="flex-shrink-0">
          <div className="flex flex-col items-center">
            <a href="https://tbdpost.com" className="flex items-center">
              <img src="/tbd_logo_black.png" alt="TBD Post Logo" className="h-12 md:h-16" />
              {/* Show title on desktop */}
              {!isCompactView && (
                <span className="ml-3 text-2xl font-bold">TBD Post DataCalc</span>
              )}
            </a>
            {/* Only show small DataCalc text on mobile */}
            {isCompactView && (
              <h1 className="mt-0 text-sm items-center font-bold md:mt-0">DataCalc</h1>
            )}
          </div>
        </div>

        {/* Right Column */}
        {isCompactView ? (
          <div className="flex flex-col justify-between flex-grow pl-4">
            {/* Dropdown */}
            <div className="flex-1 flex items-start justify-end mb-1">
              <select
                ref={selectRef}
                value={location.pathname}
                onChange={handleCalculatorChange}
                className={`${headerStyles.select(true)} max-w-[300px] w-full`}
                size={1}
              >
                {filteredMenuItems.map(item => (
                  <option key={item.id} value={item.path}>
                    {formatMenuLabel(calculatorConfig[item.id])}
                  </option>
                ))}
              </select>
            </div>
            
            {/* Icons */}
            <div className="flex-1 flex items-center justify-end">
              <div className={`w-full flex justify-between items-center px-2`}>
                {mobileMenuItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`transition-colors flex justify-center ${
                      location.pathname === item.path 
                        ? 'text-blue-500' 
                        : 'text-gray-400'
                    }`}
                  >
                    {React.cloneElement(item.icon, { 
                      size: 20
                    })}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : (
          // Desktop view
          <div className="flex-grow flex items-center justify-end">
            <select
              ref={selectRef}
              value={location.pathname}
              onChange={handleCalculatorChange}
              className={`${headerStyles.select(false)} max-w-[300px] w-full`}
              size={1}
            >
              {filteredMenuItems.map(item => (
                <option key={item.id} value={item.path}>
                  {formatMenuLabel(calculatorConfig[item.id])}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;