import { FaClock, FaClipboardList, FaHistory, FaPlayCircle } from 'react-icons/fa';
import { FaVideo, FaCalculator } from 'react-icons/fa6';
import { BsFillAspectRatioFill, BsFillStopwatchFill } from 'react-icons/bs';
import { PiFilmReelFill } from 'react-icons/pi';
import { RiFileEditFill } from 'react-icons/ri';
import { HiSparkles } from 'react-icons/hi2';
import { BiTransfer } from 'react-icons/bi';
import { MdInsertChart } from 'react-icons/md';

// Add near the top of the file
console.log('Current environment:', process.env.REACT_APP_ENVIRONMENT);

// Helper to check if we're in beta environment
export const isInBetaEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'beta';
};

// Helper to determine if a calculator should be shown in header navigation
export const shouldShowInHeader = (calculator) => {
  const isBeta = isInBetaEnvironment();
  
  if (isBeta) {
    // In beta: show everything
    return true;
  } else {
    // In production: show if not coming soon
    return !calculator.comingSoon;
  }
};

// Helper to determine if a calculator should be accessible
export const isCalculatorAccessible = (calculator) => {
  const isBeta = isInBetaEnvironment();
  
  if (isBeta) {
    // In beta: everything is accessible
    return true;
  } else {
    // In production: accessible if not coming soon
    return !calculator.comingSoon;
  }
};

// Helper to determine if "Beta" pill should be shown
export const shouldShowBetaPill = (calculator) => {
  const isBeta = isInBetaEnvironment();
  // Only show Beta pill in beta environment and only if it's marked as beta
  return isBeta && calculator.beta;
};

// Helper to determine if "Coming Soon" pill should be shown
export const shouldShowComingSoonPill = (calculator) => {
  const isBeta = isInBetaEnvironment();
  // Show Coming Soon pill in production, or in beta if it's not a beta feature
  return calculator.comingSoon && (!calculator.beta || !isBeta);
};

// Export calculator configurations
export const calculatorConfig = {
  aspectRatio: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/aspect-ratio',
    component: 'AspectRatioCalculator',
    title: 'Aspect Ratio Calculator',
    headerMenu: 'Aspect Ratio',
    sideMenu: 'Aspect Ratio',
    description: "Quickly calculate aspect ratio, width, and height based on any parameter.",
    icon: <BsFillAspectRatioFill />,
    secondaryIcon: <FaCalculator />,
  },
  bitrate: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/bitrate',
    component: 'BitrateCalculator',
    title: 'Bitrate ↔︎ File Size Calculator',
    headerMenu: 'Bitrate ↔︎ File Size',
    sideMenu: 'Bitrate ↔︎ File Size',
    description: "Calculate bitrate, file size, and video duration for your media files.",
    icon: <MdInsertChart />,
    secondaryIcon: <FaCalculator />,
  },
  camera: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/camera',
    component: 'CameraToFileSize',
    title: 'Camera ↔︎ File Size Calculator',
    headerMenu: 'Camera ↔︎ File Size',
    sideMenu: 'Camera ↔︎ File Size',
    description: "Calculate file sizes and durations based on your camera's recording settings.",
    icon: <FaVideo />,
    secondaryIcon: <FaCalculator />,
  },
  dailies: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/dailies',
    component: 'DailiesCalculator',
    title: 'Dailies Calculator',
    headerMenu: 'Dailies',
    sideMenu: 'Dailies Calculator',
    description: "Calculate total shoot data, file sizes, aspect ratios, and resolutions for dailies.",
    icon: <FaPlayCircle />,
    secondaryIcon: <FaCalculator />,
  },
  duration: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/duration',
    component: 'DurationToFileSize',
    title: 'Video Duration ↔︎ File Size Calculator',
    headerMenu: 'Duration ↔︎ File Size',
    sideMenu: 'Duration ↔︎ File Size',
    description: "Calculate video file size from duration or vice versa.",
    icon: <FaClock />,
    secondaryIcon: <FaCalculator />,
  },
  fileName: {
    production: false,
    beta: false,
    comingSoon: true,
    path: '/filename-creator',
    component: 'FileNameCreator',
    title: 'File Name Creator',
    headerMenu: 'File Name Creator',
    sideMenu: 'File Name Creator',
    description: "Generate consistent and standardized file names for better organization.",
    icon: <RiFileEditFill />,
    secondaryIcon: <HiSparkles />,
  },
  film: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/film',
    component: 'FilmCalculator',
    title: 'Motion Picture Film Calculator',
    headerMenu: 'Motion Picture Film',
    sideMenu: 'Motion Picture Film',
    description: "Calculate the amount of film stock needed based on your project's duration.",
    icon: <PiFilmReelFill />,
    secondaryIcon: <FaCalculator />,
  },
  transferTime: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/transfer-time',
    component: 'TransferTimeCalculator',
    title: 'Transfer Time Calculator',
    headerMenu: 'Transfer Time',
    sideMenu: 'Transfer Time',
    description: "Calculate data transfer times based on file size, device, and connection speed.",
    icon: <BiTransfer />,
    secondaryIcon: <BsFillStopwatchFill />,
  },
  logs: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/logs',
    component: 'Log',
    title: 'Logs',
    headerMenu: 'Logs',
    sideMenu: 'Logs',
    description: "View and share your saved calculations.",
    icon: <FaHistory />,
    secondaryIcon: <FaClipboardList />,
  }
};

// Add this helper function to features.js
export const getCalculatorName = (path) => {
  const calculator = Object.values(calculatorConfig).find(calc => {
    // Handle both exact matches and path variations
    return calc.path === path || 
           path?.startsWith(calc.path) ||
           path?.includes(calc.path.slice(1)); // handle paths without leading slash
  });
  return calculator ? calculator.title : 'Calculator';
};

export const getCalculatorHeaderName = (path) => {
  const calculator = Object.values(calculatorConfig).find(calc => {
    // Handle both exact matches and path variations
    return calc.path === path || 
           path?.startsWith(calc.path) ||
           path?.includes(calc.path.slice(1)); // handle paths without leading slash
  });
  return calculator ? calculator.headerMenu : 'Calculator';
};

