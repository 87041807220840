import React, { useState, useEffect, useRef, useCallback } from 'react';
import { SlDocs, SlNote, SlRefresh, SlLike, SlPaperPlane } from "react-icons/sl";
import { FaShareSquare, FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { getCamerasByBrand, getResolutionsForCamera, getFrameratesForCamera, getCodecsForCameraAndResolution } from '../data/cameraData';
import { codecs, dailiesCodecs } from '../data/codecData';
import { brands } from '../data/brandData';
import { resolutions } from '../data/resolutionData';
import { disableScroll, enableScroll } from '../utils/utils';
import { useCalculator } from '../context/CalculatorContext';
import { useDropdownTouch } from '../hooks/useDropdownTouch';
import { colors, spacing, layout, typography, commonStyles } from '../styles/globalStyles';
import CalculatorButtons from './CalculatorButtons';
import { handleShareOnDevice } from '../utils/shareUtils';

// Timecode class for handling timecode operations
class Timecode {
  constructor(hours = 0, minutes = 0, seconds = 0, frames = 0, frameRate = 24) {
    this.hours = Math.floor(hours);
    this.minutes = Math.floor(minutes);
    this.seconds = Math.floor(seconds);
    this.frames = Math.floor(frames);
    this.frameRate = frameRate;
  }

  // Parse a timecode string into a Timecode object
  static parse(timecodeString, frameRate = 24) {
    const parts = timecodeString.split(/[:.]/).map(Number);
    while (parts.length < 4) parts.unshift(0);
    const [hours, minutes, seconds, frames] = parts;
    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
  
  // Convert Timecode object to string
  toString() {
    return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}:${this.frames.toString().padStart(2, '0')}`;
  }

  // Calculate total frames
  totalFrames() {
    return this.hours * 3600 * this.frameRate + 
           this.minutes * 60 * this.frameRate + 
           this.seconds * this.frameRate + 
           this.frames;
  }

  // Create a Timecode object from total frames
  static fromFrames(totalFrames, frameRate = 24) {
    const hours = Math.floor(totalFrames / (3600 * frameRate));
    totalFrames %= 3600 * frameRate;
    const minutes = Math.floor(totalFrames / (60 * frameRate));
    totalFrames %= 60 * frameRate;
    const seconds = Math.floor(totalFrames / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
}

const roundToEven = (num) => {
  const rounded = Math.round(num);
  return rounded % 2 === 0 ? rounded : rounded + 1;
};


// Add the formatBitrate helper function near the top of the file
const formatBitrate = (bitrateInBytes) => {
  if (bitrateInBytes >= 1024 * 1024 * 1024) {
    return `${(bitrateInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB/s`;
  } else if (bitrateInBytes >= 1024 * 1024) {
    return `${(bitrateInBytes / (1024 * 1024)).toFixed(2)} MB/s`;
  } else if (bitrateInBytes >= 1024) {
    return `${(bitrateInBytes / 1024).toFixed(2)} KB/s`;
  } else {
    return `${bitrateInBytes.toFixed(2)} B/s`;
  }
};

const defaultAspectRatios = ["Match", "2.39:1", "2:1", "1.85:1", "1.66:1", "16:9", "4:3", 
  "21:9", "16:10", "6:5", "1:1", "4:5", "9:16", "Custom"];

// Add these validation functions at the top of the file
const validateNumber = (value, defaultValue) => {
  const num = Number(value);
  return isNaN(num) ? defaultValue : num;
};

const validateDimension = (value, defaultValue) => {
  const num = Math.round(Number(value));
  return isNaN(num) || num < 2 ? defaultValue : num;
};

const dailiesCalculator = ({ addLog }) => {
  const { dailiesState, setDailiesState } = useCalculator();
  
  // Refs - Add handleShootDaysChangeRef here
  const handleShootDaysChangeRef = useRef(null);
  const ratioRef = useRef(null);
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const resolutionRef = useRef(null);
  const codecRef = useRef(null);
  const durationRef = useRef(null);
  const framerateRef = useRef(null);
  const fileSizeRef = useRef(null);
  const logRef = useRef(null);
  const dailiesCodecRef = useRef(null);
  const dailiesFileSizeRef = useRef(null);
  

  // State variables - declare ALL state first
  const [calcData, setCalcData] = useState(dailiesState);
  const [durationInput, setDurationInput] = useState(dailiesState.durationInput);
  const [fileSizeInput, setFileSizeInput] = useState(dailiesState.fileSizeInput);
  const [fileSizeUnit, setFileSizeUnit] = useState(dailiesState.fileSizeUnit);
  const [isDurationLocked, setIsDurationLocked] = useState(dailiesState.isDurationLocked);
  const [isFileSizeLocked, setIsFileSizeLocked] = useState(dailiesState.isFileSizeLocked);
  const [calculationMode, setCalculationMode] = useState(null);
  const [resolution, setResolution] = useState("1920 x 1080");
  const [isWidthLocked, setIsWidthLocked] = useState(false);
  const [isHeightLocked, setIsHeightLocked] = useState(false);
  const [sensitivity] = useState(0.025);  const [availableCameras, setAvailableCameras] = useState(dailiesState.availableCameras);
  const [availableResolutions, setAvailableResolutions] = useState(dailiesState.availableResolutions);
  const [availableFramerates, setAvailableFramerates] = useState(dailiesState.availableFramerates);
  const [availableCodecs, setAvailableCodecs] = useState(dailiesState.availableCodecs);
  const [copyStatus, setCopyStatus] = useState(dailiesState.copyStatus);
  const [isMobile, setIsMobile] = useState(dailiesState.isMobile);
  const [copiedLogIndex, setCopiedLogIndex] = useState(dailiesState.copiedLogIndex);
  const [logs, setLogs] = useState([]);
  const [selectedRatio, setSelectedRatio] = useState("Match");
  const [customRatio, setCustomRatio] = useState("");
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1080);
  const [copyDurationText, setCopyDurationText] = useState(<SlDocs />);
  const [copyFileSizeText, setCopyFileSizeText] = useState(<SlDocs />);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [shareButtonText, setShareButtonText] = useState(<SlPaperPlane />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [customRatios, setCustomRatios] = useState([]);
  const [lockedField, setLockedField] = useState(dailiesState.lockedField);
  const [previousResolution, setPreviousResolution] = useState(dailiesState.previousResolution);
  const [shootDays, setShootDays] = useState(dailiesState.shootDays);

  // Add new state variables at the top with other state declarations
  const [footagePerDay, setFootagePerDay] = useState(dailiesState.footagePerDay);
  const [fileSizePerDay, setFileSizePerDay] = useState("");
  const [dailiesFileSizePerDay, setDailiesFileSizePerDay] = useState("43.949 GB");
  const [totalDailiesFileSize, setTotalDailiesFileSize] = useState("43.949 GB");

  // Dailies-related state
  const [dailiesData, setDailiesData] = useState({
    aspectRatio: 'Match',
    width: 1920,
    height: 1080,
    codec: 'Apple ProRes LT',
    fileSize: ''
  });


  // Update context whenever relevant state changes
  useEffect(() => {
    setDailiesState(prev => ({
      ...prev,
      footagePerDay,
      shootDays,
      durationInput,
      fileSizeInput,
      fileSizeUnit,
      dailiesFileSizePerDay,
      totalDailiesFileSize,
      selectedRatio,
      width,
      height,
      dailiesCodec: dailiesData.codec,
      lockedField,
      isWidthLocked,
      isHeightLocked,
      customRatios,
      dailiesData
    }));
  }, [
    footagePerDay,
    shootDays,
    durationInput,
    fileSizeInput,
    fileSizeUnit,
    dailiesFileSizePerDay,
    totalDailiesFileSize,
    selectedRatio,
    width,
    height,
    dailiesData,
    lockedField,
    isWidthLocked,
    isHeightLocked,
    customRatios,
    setDailiesState
  ]);

  // Update handleRatioChange to respect locks and recalculate dimensions
  const handleRatioChange = (e) => {
    const value = e.target.value;
    setSelectedRatio(value);
    
    if (value === "Custom") {
      setCustomRatio("");
      return;
    }

    if (value === "Match") {
      const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        
        if (lockedField === 'width') {
          const newHeight = roundToEven(width / cameraAspect);
          setHeight(newHeight);
          setDailiesData(prev => ({ ...prev, height: newHeight }));
        } else {
          const newHeight = roundToEven(width / cameraAspect);
          setHeight(newHeight);
          setDailiesData(prev => ({ ...prev, height: newHeight }));
        }
      }
      return;
    }

    const aspectRatio = parseRatio(value);
    if (!isNaN(aspectRatio)) {
      if (lockedField === 'width') {
        const newHeight = roundToEven(width / aspectRatio);
        setHeight(newHeight);
        setDailiesData(prev => ({ ...prev, height: newHeight }));
      } else {
        const newHeight = roundToEven(width / aspectRatio);
        setHeight(newHeight);
        setDailiesData(prev => ({ ...prev, height: newHeight }));
      }

      // Update dailies file size calculations
      if (!isDailiesFileSizeLocked) {
        calculateDailiesFileSize(footagePerDay, true);
        const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
        const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
        calculateDailiesFileSize(totalDuration, false);
      }
    }
  };

  const calculateDimensions = (changedField) => {
    const aspectRatio = parseRatio(selectedRatio);
    if (isNaN(aspectRatio)) return;

    switch (calculationMode) {
      case 'width':
        // Width is locked - only ratio changes can update height
        if (changedField === 'ratio') {
          setHeight(roundToEven(width / aspectRatio));
        }
        break;

      case 'height':
        // Height is locked - only ratio changes can update width
        if (changedField === 'ratio') {
          setWidth(roundToEven(height * aspectRatio));
        }
        break;

      case 'ratio':
        // Ratio is locked - maintain ratio while updating dimensions
        if (changedField === 'width') {
          setHeight(roundToEven(width / aspectRatio));
        } else if (changedField === 'height') {
          setWidth(roundToEven(height * aspectRatio));
        }
        break;

      default:
        // Nothing is locked - update based on changed field
        if (changedField === 'width') {
          setHeight(roundToEven(width / aspectRatio));
        } else if (changedField === 'height') {
          setWidth(roundToEven(height * aspectRatio));
        } else if (changedField === 'ratio') {
          setHeight(roundToEven(width / aspectRatio));
        }
        break;
    }
  };

  // Helper function to calculate aspect ratio without causing loops
  const recalculateDimensions = (field, context = 'manual') => {
    const aspectRatio = parseRatio(selectedRatio);
    if (isNaN(aspectRatio)) return;

    switch (calculationMode) {
      case 'width':
        setHeight(roundToEven(width / aspectRatio));
        break;
      case 'height':
        setWidth(roundToEven(height * aspectRatio));
        break;
      case 'ratio':
        if (field === 'width') {
          setHeight(roundToEven(width / aspectRatio));
        } else if (field === 'height') {
          setWidth(roundToEven(height * aspectRatio));
        }
        break;
      default:
        setHeight(roundToEven(width / aspectRatio));
        break;
    }
  };

  // Update handleWidthChange to respect locks
  const handleWidthChange = (e) => {
    const newWidth = validateDimension(e.target.value, width);
    
    if (lockedField === 'height') {
      setWidth(newWidth);
      setDailiesData(prev => ({ ...prev, width: newWidth }));
      
      if (selectedRatio !== 'Custom' && newWidth > 0) {
        const newRatio = newWidth / height;
        const formattedRatio = formatRatio(newRatio);
        if (!defaultAspectRatios.includes(formattedRatio) && 
            !customRatios.includes(formattedRatio)) {
          setCustomRatios(prevRatios => {
            const newRatios = [...prevRatios, formattedRatio];
            return newRatios.sort(compareRatios);
          });
        }
        setSelectedRatio(formattedRatio);
      }
    } else if (!lockedField || lockedField === 'width') {
      setWidth(newWidth);
      setDailiesData(prev => ({ ...prev, width: newWidth }));
      if (selectedRatio !== 'Custom') {
        const aspectRatio = parseRatio(selectedRatio);
        if (!isNaN(aspectRatio)) {
          const newHeight = roundToEven(newWidth / aspectRatio);
          setHeight(newHeight);
          setDailiesData(prev => ({ ...prev, height: newHeight }));
        }
      }
    }
  };

  // Update handleHeightChange to respect locks
  const handleHeightChange = (e) => {
    const newHeight = validateDimension(e.target.value, height);
    
    if (lockedField === 'width') {
      setHeight(newHeight);
      setDailiesData(prev => ({ ...prev, height: newHeight }));
      
      if (selectedRatio !== 'Custom' && newHeight > 0) {
        const newRatio = width / newHeight;
        const formattedRatio = formatRatio(newRatio);
        if (!defaultAspectRatios.includes(formattedRatio) && 
            !customRatios.includes(formattedRatio)) {
          setCustomRatios(prevRatios => {
            const newRatios = [...prevRatios, formattedRatio];
            return newRatios.sort(compareRatios);
          });
        }
        setSelectedRatio(formattedRatio);
      }
    } else if (!lockedField || lockedField === 'height') {
      setHeight(newHeight);
      setDailiesData(prev => ({ ...prev, height: newHeight }));
      if (selectedRatio !== 'Custom') {
        const aspectRatio = parseRatio(selectedRatio);
        if (!isNaN(aspectRatio)) {
          const newWidth = roundToEven(newHeight * aspectRatio);
          setWidth(newWidth);
          setDailiesData(prev => ({ ...prev, width: newWidth }));
        }
      }
    }
  };

  const parseRatio = (input) => {
    if (!input || typeof input !== 'string') {
      return 16/9; // Default to 16:9 if invalid input
    }

    // Remove all whitespace and convert to lowercase
    input = input.toLowerCase().replace(/\s+/g, '');

    // Check for decimal format first (e.g., 2.35 or 1.77)
    const decimalMatch = input.match(/^(\d+(?:\.\d+)?)$/);
    if (decimalMatch) {
      const result = parseFloat(input);
      return isNaN(result) ? 16/9 : result;
    }

    // Check for ratio format (e.g., 16:9, 16x9, 2.35:1)
    const ratioMatch = input.match(/^(\d+(?:\.\d+)?)[:x](\d+(?:\.\d+)?)$/);
    if (ratioMatch) {
      const [w, h] = ratioMatch.slice(1).map(Number);
      if (h === 0) return 16/9;
      const result = w / h;
      return isNaN(result) ? 16/9 : result;
    }

    // If none of the above formats match, return default
    return 16/9;
  };

  const compareRatios = (a, b) => {
    const ratioA = parseRatio(a);
    const ratioB = parseRatio(b);
    return ratioA - ratioB;
  };

  const formatRatioDisplay = (ratio) => {
    return ratio;
  };


  const handleCustomRatioChange = (e) => {
    const value = e.target.value;
    console.log('Custom ratio input:', value);
    // Allow digits, decimal point, colon, 'x', and space
    // Replace any sequence of spaces with a single space
    if (/^[\d.:x\s]*$/.test(value)) {
      const cleanedValue = value.replace(/\s+/g, ' ');
      // If there's a space, automatically convert it to a colon
      const formattedValue = cleanedValue.replace(/\s/g, ':');
      setCustomRatio(formattedValue);
      console.log('Valid input, setting custom ratio:', formattedValue);
    } else {
      console.log('Invalid input, ignoring');
    }
  };

  const handleCustomRatioKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      processCustomRatio();
    }
  };

  const handleCustomRatioBlur = () => {
    processCustomRatio();
  };

  // Update handleWidthChange to respect locks
  // const handleWidthChange = (e) => {
  //   if (lockedField === 'height') {
  //     const newWidth = validateDimension(e.target.value, dailiesData.width);
  //     setWidth(newWidth);
  //     setDailiesData(prev => ({ ...prev, width: newWidth }));
      
  //     // Calculate and set new aspect ratio when width changes with height locked
  //     if (newWidth > 0) {
  //       const newRatio = newWidth / height;
  //       const formattedRatio = formatRatio(newRatio);
        
  //       // Add to custom ratios if it's new
  //       if (!defaultAspectRatios.includes(formattedRatio) && 
  //           !customRatios.includes(formattedRatio)) {
  //         setCustomRatios(prevRatios => {
  //           const newRatios = [...prevRatios, formattedRatio];
  //           return newRatios.sort(compareRatios);
  //         });
  //       }
        
  //       setSelectedRatio(formattedRatio);
  //     }
  //   } else if (!lockedField || lockedField === 'width') {
  //     const newWidth = validateDimension(e.target.value, dailiesData.width);
  //     setWidth(newWidth);
  //     setDailiesData(prev => ({ ...prev, width: newWidth }));
  //     calculateDimensions('width');
  //   }
  // };

  // // Update handleHeightChange to respect locks
  // const handleHeightChange = (e) => {
  //   if (lockedField === 'width') {
  //     const newHeight = validateDimension(e.target.value, dailiesData.height);
  //     setHeight(newHeight);
  //     setDailiesData(prev => ({ ...prev, height: newHeight }));
      
  //     // Calculate and set new aspect ratio when height changes with width locked
  //     if (newHeight > 0) {
  //       const newRatio = width / newHeight;
  //       const formattedRatio = formatRatio(newRatio);
        
  //       // Add to custom ratios if it's new
  //       if (!defaultAspectRatios.includes(formattedRatio) && 
  //           !customRatios.includes(formattedRatio)) {
  //         setCustomRatios(prevRatios => {
  //           const newRatios = [...prevRatios, formattedRatio];
  //           return newRatios.sort(compareRatios);
  //         });
  //       }
        
  //       setSelectedRatio(formattedRatio);
  //     }
  //   } else if (!lockedField || lockedField === 'height') {
  //     const newHeight = validateDimension(e.target.value, dailiesData.height);
  //     setHeight(newHeight);
  //     setDailiesData(prev => ({ ...prev, height: newHeight }));
  //     calculateDimensions('height');
  //   }
  // };


  const handleWidthBlur = () => {
    const newWidth = validateDimension(width, 1920);
    setWidth(newWidth);
    setDailiesData(prev => ({ ...prev, width: newWidth }));
    
    if (selectedRatio !== 'Custom' && !lockedField) {
      const aspectRatio = parseRatio(selectedRatio);
      if (!isNaN(aspectRatio)) {
        const newHeight = roundToEven(newWidth / aspectRatio);
        setHeight(newHeight);
        setDailiesData(prev => ({ ...prev, height: newHeight }));
      }
    }
  };

  const handleHeightBlur = () => {
    const newHeight = validateDimension(height, 1080);
    setHeight(newHeight);
    setDailiesData(prev => ({ ...prev, height: newHeight }));
    
    if (selectedRatio !== 'Custom' && !lockedField) {
      const aspectRatio = parseRatio(selectedRatio);
      if (!isNaN(aspectRatio)) {
        const newWidth = roundToEven(newHeight * aspectRatio);
        setWidth(newWidth);
        setDailiesData(prev => ({ ...prev, width: newWidth }));
      }
    }

    // Recalculate dailies file size
    if (!isDailiesFileSizeLocked) {
      calculateDailiesFileSize(footagePerDay, true);
      const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
      const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
      calculateDailiesFileSize(totalDuration, false);
    }
  };

  const handleWidthKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleHeightKeyPress = (e) => {
    if (e.key === 'Enter') {
      const newHeight = roundToEven(parseInt(height, 10) || 0);
      setHeight(newHeight);

      if (lockedField === 'width') {
        const newRatio = width / newHeight;
        const formattedRatio = formatRatio(newRatio);
        setSelectedRatio(formattedRatio);
      } else if (!lockedField || lockedField === 'ratio') {
        const aspectRatio = parseRatio(selectedRatio);
        const newWidth = roundToEven(newHeight * aspectRatio);
        setWidth(newWidth);
      }

      e.target.blur();
    }
  };

  // Update handleWidthWheel to use rounded values
  const handleWidthWheel = (e) => {
    e.preventDefault();
    if (lockedField === 'height') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    const scrollAmount = -e.deltaY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(scrollAmount) > 50 
      ? baseChange * Math.abs(scrollAmount / 50) 
      : baseChange;
    const delta = Math.sign(scrollAmount);
    
    const newWidth = Math.max(2, width + (delta * changeAmount));
    handleWidthChange({ target: { value: roundToEven(newWidth) } });
  };

  // Update handleHeightWheel to use rounded values
  const handleHeightWheel = (e) => {
    e.preventDefault();
    if (lockedField === 'width') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const isLeftHalf = x < rect.width / 2;
    const scrollAmount = -e.deltaY;
    const baseChange = isLeftHalf ? 20 : 2;
    const changeAmount = Math.abs(scrollAmount) > 50 
      ? baseChange * Math.abs(scrollAmount / 50) 
      : baseChange;
    const delta = Math.sign(scrollAmount);
    
    const newHeight = Math.max(2, height + (delta * changeAmount));
    setHeight(roundToEven(newHeight));
    setDailiesData(prev => ({ ...prev, height: roundToEven(newHeight) }));
  };

  const updateRatio = (newRatio) => {
    const formattedRatio = formatRatio(newRatio);
    if (!defaultAspectRatios.includes(formattedRatio) && !customRatios.includes(formattedRatio)) {
      setCustomRatios(prevRatios => {
        const newRatios = [...prevRatios, formattedRatio];
        return newRatios.sort(compareRatios);
      });
    }
    setSelectedRatio(formattedRatio);
    setCustomRatio(formattedRatio);
  };

  const handleLockClick = () => {
    if (!lockedField) {
      setLockedField('width');
      setIsWidthLocked(true);
      setIsHeightLocked(false);
    } else if (lockedField === 'width') {
      setLockedField('height');
      setIsWidthLocked(false);
      setIsHeightLocked(true);
    } else {
      setLockedField(null);
      setIsWidthLocked(false);
      setIsHeightLocked(false);
    }

    // Maintain aspect ratio when changing lock state
    if (selectedRatio !== 'Custom') {
      const aspectRatio = parseRatio(selectedRatio);
      if (!isNaN(aspectRatio)) {
        if (lockedField === 'width') {
          const newHeight = roundToEven(width / aspectRatio);
          setHeight(newHeight);
          setDailiesData(prev => ({ ...prev, height: newHeight }));
        } else if (lockedField === 'height') {
          const newWidth = roundToEven(height * aspectRatio);
          setWidth(newWidth);
          setDailiesData(prev => ({ ...prev, width: newWidth }));
        }
      }
    }
  };


  const calculateDuration = (data = calcData, fileSizeInput) => {
    if (!data) {
      console.error('Invalid data for duration calculation');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // if (!data || !data.fileSize || !data.fileSizeUnit) {
    //   console.error('Invalid data for duration calculation');
    //   return "00:00:00:01"; // Ensure at least 1 frame
    // }

    const selectedCodec = codecs.find(codec => codec.name === data.codec);
    if (!selectedCodec) {
      console.error('Invalid codec');
      return "00:00:00:01";
    }

    const datarate = selectedCodec.datarate;
    const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
    let resolutionValue;
    if (resolutionMatch) {
      const [, width, height] = resolutionMatch;
      resolutionValue = parseInt(width) * parseInt(height);
    } else {
      console.error('Invalid resolution format');
      return "00:00:00:01";
    }

    const fps = parseFloat(data.framerate);
    const parsedSize = parseFileSize(fileSizeInput || `${data.fileSize} ${fileSizeUnit}`);
    if (!parsedSize) {
      console.error('Invalid file size');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // Unit multipliers for file size calculations
    const unitMultiplier = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024
    };

    const size_in_bytes = parseFloat(parsedSize.size) * unitMultiplier[parsedSize.unit];

    const bytes_per_second = datarate * resolutionValue * fps;

    let total_seconds = size_in_bytes / (bytes_per_second * 1024 * 1024 / 8);

    console.log("Calculated total seconds:", total_seconds); // Debug log

    // Ensure the total_seconds is at least enough for one frame
    if (total_seconds < 1 / fps) {
      total_seconds = 1 / fps;
    }

    const hours = Math.floor(total_seconds / 3600);
    total_seconds %= 3600;
    const minutes = Math.floor(total_seconds / 60);
    total_seconds %= 60;
    const seconds = Math.floor(total_seconds);
    const frames = Math.round((total_seconds - seconds) * fps);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
  };

  // Convert resolutions object to array for select options
  const resolutionOptions = Object.keys(resolutions);

  // Get resolution string for display
  let resolutionString;
  if (calcData.resolution === 'Custom') {
    resolutionString = calcData.customResolution;
  } else {
    const match = calcData.resolution.match(/\((\d+\s*x\s*\d+)\)/);
    resolutionString = match ? match[1].replace(/\s+/g, '') : calcData.resolution;
  }

  // Ref for previous calcData
  const prevCalcData = useRef(calcData);

  // Effect to check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Update the handleDurationLockClick function
  const handleDurationLockClick = () => {
    if (isDurationLocked) {
      // Unlock duration
      setIsDurationLocked(false);
      setCalculationMode(null);
    } else {
      // Lock duration and unlock file size
      setIsDurationLocked(true);
      setIsFileSizeLocked(false);
      setCalculationMode('duration');
      setCalcData(prevData => ({
        ...prevData,
        duration: durationInput,
        fileSize: parseFloat(fileSizeInput.split(' ')[0]) // Ensure fileSize is correctly set
      }));
    }
  };

  // Handler for file size lock click
  const handleFileSizeLockClick = () => {
    if (isFileSizeLocked) {
      // Unlock file size
      setIsFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Lock file size and unlock duration
      setIsFileSizeLocked(true);
      setIsDurationLocked(false);
      setCalculationMode('fileSize');
      setCalcData(prevData => ({
        ...prevData,
        fileSize: parseFloat(fileSizeInput.split(' ')[0]),
        duration: durationInput
      }));
    }
  };

  useEffect(() => {
    const cameras = getCamerasByBrand(calcData.brand);
    setAvailableCameras(cameras);
    
    if (!cameras.includes(calcData.camera)) {
      const newCamera = cameras[0] || '';
      setCalcData(prev => ({ 
        ...prev, 
        camera: newCamera,
        resolution: '', // Reset resolution
        codec: '',      // Reset codec
        framerate: ''   // Reset framerate
      }));
    }
  }, [calcData.brand]);

  useEffect(() => {
    if (calcData.camera) {
      try {
        const resolutions = getResolutionsForCamera(calcData.camera);
        setAvailableResolutions(resolutions);
        
        if (!resolutions.includes(calcData.resolution)) {
          const newResolution = resolutions[0] || '';
          setCalcData(prev => ({ 
            ...prev, 
            resolution: newResolution,
            codec: '',      // Reset codec
            framerate: ''   // Reset framerate
          }));
        }
      } catch (error) {
        console.error('Error getting resolutions:', error);
        setAvailableResolutions([]);
      }
    }
  }, [calcData.camera]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution) {
      try {
        const codecs = getCodecsForCameraAndResolution(calcData.camera, calcData.resolution);
        setAvailableCodecs(codecs);
        
        if (!codecs.includes(calcData.codec)) {
          const newCodec = codecs[0] || '';
          setCalcData(prev => ({ 
            ...prev, 
            codec: newCodec,
            framerate: '' // Reset framerate
          }));
        }
      } catch (error) {
        console.error('Error getting codecs:', error);
        setAvailableCodecs([]);
      }
    }
  }, [calcData.camera, calcData.resolution]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution && calcData.codec) {
      updateFramerateForCodec(calcData.camera, calcData.resolution, calcData.codec);
    }
  }, [calcData.camera, calcData.resolution, calcData.codec]);

  const updateFramerateForCodec = (camera, resolution, codec) => {
    const framerates = getFrameratesForCamera(camera, resolution, codec);
    setAvailableFramerates(framerates);
    
    if (!framerates.includes(calcData.framerate)) {
      const defaultFramerate = '23.976';
      const newFramerate = framerates.includes(defaultFramerate) ? defaultFramerate : framerates[0] || '';
      setCalcData(prev => ({ ...prev, framerate: newFramerate }));
    }
  };

  const calculateFileSize = (data = calcData) => {
    try {
      console.log("Starting file size calculation with data:", data);

      const [hours, minutes, seconds, frames] = data.duration.split(':').map(Number);
      const totalFrames = (hours * 3600 + minutes * 60 + seconds) * parseFloat(data.framerate) + frames;
      console.log("Total frames:", totalFrames);

      let resolutionValue;
      const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, width, height] = resolutionMatch;
        resolutionValue = parseInt(width) * parseInt(height);
        console.log("Resolution parsed from string:", width, "x", height);
      } else {
        const resolutionData = resolutions[data.resolution];
        if (!resolutionData || !resolutionData.dimensions) {
          throw new Error(`Invalid resolution: ${data.resolution}`);
        }
        const [width, height] = resolutionData.dimensions;
        resolutionValue = width * height;
      }
      console.log("Resolution value:", resolutionValue);

      const selectedCodec = codecs.find(codec => codec.name === data.codec);
      console.log("Selected codec:", selectedCodec);
      if (!selectedCodec) {
        throw new Error(`Invalid codec: ${data.codec}`);
      }

      const megapixels = resolutionValue; // Convert to megapixels
      const datarate = selectedCodec.datarate;
      const fileSizeGB = (totalFrames * megapixels * datarate) / 8 / 1024;
      console.log("Calculated file size in GB:", fileSizeGB);

      const { size, unit } = formatFileSize(fileSizeGB);
      console.log("Formatted file size:", size, unit);

      return { size, unit };
    } catch (error) {
      console.error("Error calculating file size:", error.message);
      return { size: '0', unit: 'B' };
    }
  };

  // Replace both calculateDailiesFileSize functions with this single version
  const calculateDailiesFileSize = useCallback((duration, isPerDay = false) => {
    try {
      if (!duration || !calcData.framerate || !dailiesData.width || !dailiesData.height || !dailiesData.codec) {
        console.log("Missing required data for dailies calculation");
        const defaultSize = isPerDay ? "43.949 GB" : "43.949 GB";
        if (isPerDay) {
          setDailiesFileSizePerDay(defaultSize);
        } else {
          setTotalDailiesFileSize(defaultSize);
          setDailiesFileSize(defaultSize);
        }
        return;
      }

      // Parse duration into frames
      const [hours, minutes, seconds, frames] = duration.split(':').map(Number);
      const framerate = validateNumber(parseFloat(calcData.framerate), 23.976);
      const totalFrames = (hours * 3600 + minutes * 60 + seconds) * framerate + frames;
      
      // Calculate resolution value (total pixels)
      const resolutionValue = validateDimension(dailiesData.width, 1920) * 
                             validateDimension(dailiesData.height, 1080);
      
      // Find the selected codec and its datarate
      const selectedCodec = dailiesCodecs.find(codec => codec.name === dailiesData.codec) || 
                           dailiesCodecs.find(codec => codec.name === 'Apple ProRes LT');
      
      // Calculate file size
      const datarate = selectedCodec.datarate;
      const fileSizeGB = (totalFrames * resolutionValue * datarate) / 8 / 1024;
      const { size, unit } = formatFileSize(fileSizeGB);
      
      // Update the appropriate state based on isPerDay flag
      if (isPerDay) {
        setDailiesFileSizePerDay(`${size} ${unit}`);
      } else {
        setTotalDailiesFileSize(`${size} ${unit}`);
        setDailiesFileSize(`${size} ${unit}`);
      }

    } catch (error) {
      console.error("Error calculating dailies file size:", error);
      const defaultSize = isPerDay ? "43.949 GB" : "43.949 GB";
      if (isPerDay) {
        setDailiesFileSizePerDay(defaultSize);
      } else {
        setTotalDailiesFileSize(defaultSize);
        setDailiesFileSize(defaultSize);
      }
    }
  }, [calcData.framerate, dailiesData.width, dailiesData.height, dailiesData.codec]);


  // Update recalculateFileSizes to handle all calculations
  const recalculateFileSizes = useCallback((newData = calcData, newFootagePerDay = footagePerDay, newShootDays = shootDays) => {
    try {
      // 1. Calculate file size per day for OCF
      const { size: perDaySize, unit: perDayUnit } = calculateFileSize({ 
        ...newData,
        duration: newFootagePerDay 
      });
      setFileSizePerDay(`${perDaySize} ${perDayUnit}`);
      
      // 2. Calculate total duration based on shoot days
      const totalFrames = Timecode.parse(newFootagePerDay, parseFloat(newData.framerate)).totalFrames() * newShootDays;
      const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(newData.framerate)).toString();
      setDurationInput(totalDuration);
      
      // 3. Calculate total OCF file size
      const { size: totalSize, unit: totalUnit } = calculateFileSize({ 
        ...newData,
        duration: totalDuration 
      });
      setFileSizeInput(`${totalSize} ${totalUnit}`);
      setFileSizeUnit(totalUnit);

      // 4. Calculate dailies file size per day
      calculateDailiesFileSize(newFootagePerDay, true);
      
      // 5. Calculate total dailies file size
      calculateDailiesFileSize(totalDuration, false);

    } catch (error) {
      console.error("Error updating calculations:", error);
    }
  }, [calcData, footagePerDay, shootDays, calculateFileSize, calculateDailiesFileSize]);

  // Add this effect to trigger recalculation when relevant values change
  useEffect(() => {
    recalculateFileSizes(calcData, footagePerDay, shootDays);
  }, [
    calcData.brand,
    calcData.camera,
    calcData.resolution,
    calcData.codec,
    calcData.framerate,
    footagePerDay,
    shootDays,
    dailiesData.width,
    dailiesData.height,
    dailiesData.codec,
    recalculateFileSizes
  ]);

  // Update handleInputChange to trigger recalculations
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    const newCalcData = {
      ...calcData,
      [name]: value
    };
    
    setCalcData(newCalcData);
    recalculateFileSizes(newCalcData, footagePerDay, shootDays);
  };

  // // Move handleShootDaysChange before handleTouchStart and wrap it in useCallback
  // const handleShootDaysChange = useCallback((e) => {
  //   const value = Math.max(1, parseInt(e.target.value) || 1);
  //   setShootDays(value);
  //   recalculateFileSizes(calcData, footagePerDay, value);
  // }, [calcData, footagePerDay, recalculateFileSizes]);

  // Update handleDailiesCodecChange
  const handleDailiesCodecChange = (e) => {
    const newCodec = e.target.value;
    setDailiesData(prev => ({ ...prev, codec: newCodec }));
    
    // Recalculate all dailies sizes with new codec
    calculateDailiesFileSize(footagePerDay, true);
    
    const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
    const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
    calculateDailiesFileSize(totalDuration, false);
  };

  const handleBlur = () => {
    calculateDimensions();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      calculateDimensions();
    }
  };

  // Function to format duration
  const formatDuration = (value) => {
    const parts = value.split(':').filter(part => part !== '');
    while (parts.length < 4) {
      parts.unshift('00');
    }
    return parts.join(':');
  };

  // Handler for duration focus
  const handleDurationFocus = () => {
    if (!isDurationLocked) {
      setDurationInput('');
    }
  };

  // Function to parse duration input
  const parseDuration = (input, framerate) => {
    // Replace all periods with ':00' to ensure they become two zeros
    input = input.replace(/\./g, ':00');

    if (!input || input.trim() === '') {
      return new Timecode(0, 0, 0, 0, framerate).toString();
    }

    input = input.trim().toLowerCase();

    // Handle single number input - interpret as seconds by default
    if (/^\d+$/.test(input)) {
      const seconds = parseInt(input, 10);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      
      return new Timecode(hours, minutes, remainingSeconds, 0, framerate).toString();
    }

    // Handle input with colons after replacing periods
    if (/^\d+([:]\d+)*$/.test(input)) {
      const parts = input.split(':').map(Number);
      while (parts.length < 4) parts.unshift(0);
      let [hours, minutes, seconds, frames] = parts;

      // Adjust for overflow
      if (frames >= framerate) {
        seconds += Math.floor(frames / framerate);
        frames %= framerate;
      }
      if (seconds >= 60) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
      }
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }

      // Ensure at least 1 frame
      const totalFrames = Math.max(1, (hours * 3600 + minutes * 60 + seconds) * framerate + frames);
      
      return Timecode.fromFrames(totalFrames, framerate).toString();
    }

    // Define regex patterns for different time units
    const patterns = {
      week: /(\d+)\s*(?:w|wk|wks|week|weeks)/i,
      day: /(\d+)\s*(?:d|dy|dys|day|days)/i,
      hour: /(\d+)\s*(?:h|hr|hrs|hour|hours)/i,
      minute: /(\d+)\s*(?:m|min|mins|minute|minutes)/i,
      second: /(\d+)\s*(?:s|sec|secs|second|seconds)/i,
      frame: /(\d+)\s*(?:f|fr|frm|frms|frame|frames)/i
    };

    let totalFrames = 0;

    Object.entries(patterns).forEach(([unit, pattern]) => {
      const match = input.match(pattern);
      if (match) {
        const value = parseInt(match[1]);
        switch (unit) {
          case 'week': totalFrames += value * 7 * 24 * 3600 * framerate; break;
          case 'day': totalFrames += value * 24 * 3600 * framerate; break;
          case 'hour': totalFrames += value * 3600 * framerate; break;
          case 'minute': totalFrames += value * 60 * framerate; break;
          case 'second': totalFrames += value * framerate; break;
          case 'frame': totalFrames += value; break;
        }
      }
    });

    if (totalFrames === 0) {
      // If no valid time units were found, try parsing as a single number (assume seconds)
      const totalSeconds = parseFloat(input);
      if (!isNaN(totalSeconds)) {
        totalFrames = Math.round(totalSeconds * framerate);
      } else {
        throw new Error("Invalid duration format");
      }
    }

    return Timecode.fromFrames(totalFrames, framerate).toString();
  };

  const handleShare = async () => {
    const shareUrl = new URL(window.location.href);
    shareUrl.searchParams.set('brand', calcData.brand);
    shareUrl.searchParams.set('camera', calcData.camera);
    shareUrl.searchParams.set('resolution', calcData.resolution);
    shareUrl.searchParams.set('codec', calcData.codec);
    shareUrl.searchParams.set('framerate', calcData.framerate);
    shareUrl.searchParams.set('duration', durationInput);
    shareUrl.searchParams.set('fileSize', fileSizeInput);
    shareUrl.searchParams.set('dailiesCodec', dailiesData.codec);
    shareUrl.searchParams.set('dailiesWidth', dailiesData.width.toString());
    shareUrl.searchParams.set('dailiesHeight', dailiesData.height.toString());
    if (calculationMode) {
      shareUrl.searchParams.set('mode', calculationMode);
    }

    const shareText = `${calcData.brand} ${calcData.camera} - ${calcData.resolution}\n` +
                     `${calcData.codec} @ ${calcData.framerate}fps\n` +
                     `Dailies: ${dailiesData.codec} (${dailiesData.width}x${dailiesData.height})\n` +
                     `${durationInput} = ${dailiesFileSizePerDay} per day`;
  
    try {
      const result = await handleShareOnDevice(
        'TBD Post DataCalc',
        `Check out this dailies calculation: ${shareText}`,
        shareUrl.toString()
      );

      if (result?.success) {
        setShareButtonText(<SlLike />);
        setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
      }
      return result;
    } catch (error) {
      console.error('Share failed:', error);
      return { success: false, method: 'none' };
    }
  };

  const handleShareSuccess = () => {
    setShareButtonText(<SlLike />);
    setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
  };

  const handleShareError = (error) => {
    console.error('Failed to copy or share: ', error);
  };

  const handleShareLog = () => {
    const shareUrl = generateShareUrl();
    handleShare(isMobile, 'Camera to File Size', `${calcData.brand} ${calcData.camera} - ${calcData.resolution} ${calcData.codec} @ ${calcData.framerate}fps ${calcData.duration} = ${fileSizeInput}`, shareUrl, () => {
      setShareButtonText(<SlLike />);
      setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
    });
  };

  const handleShareLogError = (error) => {
    console.error('Failed to copy or share: ', error);
  };

  const generateShareUrl = () => {
    const params = new URLSearchParams({
      duration: calcData.duration,
      brand: calcData.brand,
      camera: calcData.camera,
      resolution: calcData.resolution,
      codec: calcData.codec,
      framerate: calcData.framerate,
      fileSize: fileSizeInput,
      calculationMode: calculationMode || '',
      lockedField: lockedField || ''
    });
    return `${window.location.origin}${window.location.pathname}?${params.toString()}`;
  };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('duration')) {
      const newCalcData = {
        duration: params.get('duration') || '01:00:00:00',
        brand: params.get('brand') || 'ARRI',
        camera: params.get('camera') || 'ALEXA 35',
        resolution: params.get('resolution') || '4.6K 3:2 Open Gate (4608 x 3164)',
        codec: params.get('codec') || 'ARRIRAW',
        framerate: params.get('framerate') || '23.976',
      };
      setCalcData(newCalcData);
      setDurationInput(params.get('duration') || '01:00:00:00');
      setFileSizeInput(params.get('fileSize') || '1.915 TB');
    }
  }, []);

  // Handler for duration blur event
  const handleDurationBlur = () => {
    if (durationInput.trim() === '') {
      setDurationInput('');
      if (!isDurationLocked) {
        setCalcData(prevData => ({ ...prevData, duration: '' }));
      }
    } else {
      try {
        const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
        setDurationInput(parsedDuration);
        if (!isDurationLocked) {
          setCalcData(prevData => ({
            ...prevData,
            duration: parsedDuration
          }));
          // Recalculate file size if needed
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize();
            setFileSizeInput(`${size} ${unit}`);
            setFileSizeUnit(unit);
          }
        }
      } catch (error) {
        console.error("Invalid duration format");
        setDurationInput('');
        if (!isDurationLocked) {
          setCalcData(prevData => ({ ...prevData, duration: '' }));
        }
      }
    }
  };

  // Handler for duration key press
  const handleDurationKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isDurationLocked) {
        try {
          const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
          setDurationInput(parsedDuration);
          setCalcData(prev => ({
            ...prev,
            duration: parsedDuration
          }));
          // Recalculate file size based on the parsed duration
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize({ ...calcData, duration: parsedDuration });
            setFileSizeInput(`${size} ${unit}`);
            setCalcData(prevData => ({
              ...prevData,
              fileSize: size
            }));
            setFileSizeUnit(unit);
          }
          // Blur the input field
          e.target.blur();
        } catch (error) {
          console.error("Invalid duration format");
          // Optionally, notify the user or revert to the previous valid duration
        }
      }
    }
  };

  const handleWheel = (e, options, currentValue, setterFunction, fieldType) => {
    e.preventDefault();
    const index = options.indexOf(currentValue);
    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity;
    
    let newIndex = index + Math.sign(scrollAmount);
    newIndex = Math.max(0, Math.min(newIndex, options.length - 1));
    
    if (newIndex !== index) {
      const newValue = options[newIndex];
      
      // Recalculate based on lock state
      if (calculationMode === 'fileSize') {
        // If file size is locked, only update duration
        const duration = calculateDuration({ 
          ...calcData, 
          [fieldType]: newValue 
        }, fileSizeInput);
        
        setDurationInput(duration);
        setCalcData(prev => ({
          ...prev,
          [fieldType]: newValue,
          duration: duration
        }));
      } else {
        // If duration is locked or nothing is locked, update file size
        const { size, unit } = calculateFileSize({ 
          ...calcData, 
          [fieldType]: newValue 
        });
        
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prev => ({
          ...prev,
          [fieldType]: newValue,
          fileSize: size
        }));
        setFileSizeUnit(unit);
      }
    }
  };

  // Update handleDurationWheel to include file size calculations
  const handleDurationWheel = (e) => {
    e.preventDefault();
    if (isDurationLocked) return; // Immediately return if duration is locked

    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity; // Note the negative sign here
    
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    
    let [hours, minutes, seconds, frames] = calcData.duration.split(':').map(Number);
    const framerate = parseFloat(calcData.framerate);
    
    if (x < width * 0.25) {
      // Scrolling hours
      hours += Math.sign(scrollAmount);
      hours = Math.max(0, hours);
    } else if (x < width * 0.5) {
      // Scrolling minutes
      minutes += Math.sign(scrollAmount);
      if (minutes < 0) {
        if (hours > 0) {
          hours--;
          minutes = 59;
        } else {
          minutes = 0;
        }
      } else if (minutes >= 60) {
        hours++;
        minutes = 0;
      }
    } else if (x < width * 0.75) {
      // Scrolling seconds
      seconds += Math.sign(scrollAmount);
      if (seconds < 0) {
        if (minutes > 0) {
          minutes--;
          seconds += 60;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds += 60;
        } else {
          seconds = 0;
        }
      }
      while (seconds >= 60) {
        minutes++;
        seconds -= 60;
        if (minutes >= 60) {
          hours++;
          minutes -= 60;
        }
      }
    } else {
      // Scrolling frames
      frames += Math.sign(scrollAmount);
      if (frames < 0) {
        if (seconds > 0) {
          seconds--;
          frames += Math.floor(framerate);
        } else if (minutes > 0) {
          minutes--;
          seconds = 59;
          frames += Math.floor(framerate);
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
          frames += Math.floor(framerate);
        } else {
          frames = 0;
        }
      } else if (frames >= Math.floor(framerate)) {
        seconds++;
        frames -= Math.floor(framerate);
        if (seconds >= 60) {
          minutes++;
          seconds -= 60;
          if (minutes >= 60) {
            hours++;
            minutes -= 60;
          }
        }
      }
    }


    const newFootagePerDay = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
    setFootagePerDay(newFootagePerDay);
    recalculateFileSizes(calcData, newFootagePerDay);
  };


  // Helper function to format file size
  const formatFileSize = (sizeInGB) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let size = sizeInGB * 1024 * 1024 * 1024; // Convert GB to bytes
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return {
      size: size.toFixed(3),
      unit: units[unitIndex]
    };
  };

  // 8. Modify useEffect to handle recalculations based on calculationMode
  useEffect(() => {
    // Skip calculations if the resolution is set to 'Custom' and the custom resolution is not yet confirmed
    if (calcData.resolution === 'Custom' && !calcData.customResolution) {
      return;
    }

    recalculateBasedOnLockState();
  }, [
    calcData.resolution,
    calcData.codec,
    calcData.framerate,
    calcData.duration,
    calcData.fileSize,
    isDurationLocked,
    isFileSizeLocked,
    calculationMode
  ]);

  useEffect(() => {
    const resolutionElement = resolutionRef.current;
    const framerateElement = framerateRef.current;
    const codecElement = codecRef.current;

    const elements = [
      { ref: resolutionElement, handler: handleResolutionWheel },
      { ref: framerateElement, handler: handleFramerateWheel },
      { ref: codecElement, handler: handleCodecWheel },
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref) {
        ref.addEventListener('wheel', handler, { passive: false });
      }
    });

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref) {
          ref.removeEventListener('wheel', handler);
        }
      });
    };
  }, [calcData.resolution, calcData.framerate, calcData.codec]);

  useEffect(() => {
    const durationElement = durationRef.current;

    const wheelHandler = (e) => {
      if (!isDurationLocked) {
        handleDurationWheel(e);
      }
    };

    if (durationElement) {
      durationElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (durationElement) {
        durationElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.duration, calcData.framerate, isDurationLocked]);

  const handleFileSizeClick = () => {
    if (isMobile) return;

    const fileSizeText = `${calcData.fileSize} ${fileSizeUnit}`;
    navigator.clipboard.writeText(fileSizeText).then(() => {
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleLogClick = () => {
    const logEntry = `${calcData.brand} ${calcData.camera} - ${calcData.resolution} \n${calcData.codec} @ ${calcData.framerate}fps (${calculateBitrate()}) \n${calcData.duration} = ${fileSizeInput}\r\n`;
    addLog(logEntry);

    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);

    // Copy to clipboard functionality
    if (navigator.clipboard) {
      navigator.clipboard.writeText(logEntry)
        .then(() => {
          // You can add a visual feedback here if needed
        })
        .catch(err => {
          console.error('Failed to copy log entry: ', err);
        });
    }
  };

  const copyLogToClipboard = (log, index) => {
    if (isMobile) return; 

    const logWithLineBreak = `${log}\r\n`; // Add line break

    navigator.clipboard.writeText(logWithLineBreak).then(() => {
      setCopiedLogIndex(index);
      setTimeout(() => setCopiedLogIndex(null), 3000);
    }).catch(err => {
      console.error('Failed to copy log: ', err);
      setCopiedLogIndex(null);
    });
  };

  const handleBrandWheel = (e) => {
    handleWheel(
      e,
      brands,
      calcData.brand,
      (newBrand) => {
        setCalcData(prev => ({ ...prev, brand: newBrand }));
        recalculateBasedOnLockState();
      }
    );
  };

  const handleCameraWheel = (e) => {
    handleWheel(
      e,
      availableCameras,
      calcData.camera,
      (newCamera) => {
        setCalcData(prev => ({ ...prev, camera: newCamera }));
        recalculateBasedOnLockState();
      }
    );
  };

  const handleResolutionWheel = (e) => {
    handleWheel(
      e, 
      availableResolutions, 
      calcData.resolution, 
      (newResolution) => {
        setCalcData(prev => ({ ...prev, resolution: newResolution }));
        recalculateBasedOnLockState(newResolution);
      }
    );
  };

  const handleFramerateWheel = (e) => {
    handleWheel(
      e, 
      availableFramerates, 
      calcData.framerate, 
      (newFramerate) => {
        setCalcData(prev => ({ ...prev, framerate: newFramerate }));
        recalculateBasedOnLockState();
      }
    );
  };

  // Modify the handleCodecWheel function
  const handleCodecWheel = (e) => {
    handleWheel(
      e,
      availableCodecs,
      calcData.codec,
      (newCodec) => {
        setCalcData(prev => ({ ...prev, codec: newCodec }));
        
        // Calculate file size per day with new codec
        const { size: perDaySize, unit: perDayUnit } = calculateFileSize({ 
          ...calcData, 
          codec: newCodec,
          duration: footagePerDay 
        });
        setFileSizePerDay(`${perDaySize} ${perDayUnit}`);
        
        // Calculate total OCF file size based on shoot days
        const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
        const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
        
        const { size, unit } = calculateFileSize({ 
          ...calcData, 
          codec: newCodec,
          duration: totalDuration 
        });
        setFileSizeInput(`${size} ${unit}`);
        setFileSizeUnit(unit);
      }
    );
  };

  // Modify the useEffect for codec ref
  useEffect(() => {
    const codecElement = codecRef.current;

    const wheelHandler = (e) => {
      handleCodecWheel(e);
    };

    if (codecElement) {
      codecElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (codecElement) {
        codecElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.camera, calcData.resolution, calcData.codec, availableCodecs]);

  // Add a new useEffect to ensure codec is always valid
  useEffect(() => {
    if (availableCodecs.length > 0 && !availableCodecs.includes(calcData.codec)) {
      const newCodec = availableCodecs[0];
      setCalcData(prev => ({ ...prev, codec: newCodec }));
      updateFramerateForCodec(calcData.camera, calcData.resolution, newCodec);
    }
  }, [calcData.camera, calcData.resolution, calcData.codec, availableCodecs]);

  const handleDeleteLog = (index) => {
    setLogs(prevLogs => prevLogs.filter((_, i) => i !== index));
  };

  const handleReset = () => {
    // Reset all values to context defaults
    setShootDays(1);
    setFootagePerDay("04:00:00:00");
    setDurationInput("04:00:00:00");
    setFileSizeInput("1.91 TB");
    setFileSizeUnit("TB");
    setDailiesFileSizePerDay("43.949 GB");
    setTotalDailiesFileSize("43.949 GB");
    
    // Reset dailies values
    setSelectedRatio("Match");
    setCustomRatio("");
    setWidth(1920);
    setHeight(1080);
    setDailiesData({
      aspectRatio: 'Match',
      width: 1920,
      height: 1080,
      codec: 'Apple ProRes LT',
      fileSize: ''
    });

    // Reset locks
    setIsWidthLocked(false);
    setIsHeightLocked(false);
    setCalculationMode(null);
    setLockedField(null);

    // Show reset confirmation
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);

  // 3. Update handleTouchStart to allow default behavior for select fields
  const handleTouchStart = useCallback((e, field) => {
    if (field === 'shootDays') {
      e.preventDefault();
      const touch = e.touches[0];
      const startY = touch.clientY;
      let lastY = startY;
      
      const handleTouchMove = (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        const currentY = touch.clientY;
        const diffY = lastY - currentY;
        const delta = Math.sign(diffY);
        
        const newValue = Math.max(1, shootDays + delta);
        if (newValue !== shootDays) {
          setShootDays(newValue);
          if (handleShootDaysChangeRef.current) {
            handleShootDaysChangeRef.current({ target: { value: newValue } });
          }
        }
        
        lastY = currentY;
      };

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      };

      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
      return;
    }
    
    if ((field === 'fileSize' && isFileSizeLocked) || (field === 'duration' && isDurationLocked)) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let startValue;

    if (field === 'fileSize') {
      const parsedSize = parseFileSize(fileSizeInput);
      startValue = parsedSize ? parseFloat(parsedSize.size) : 0;
    } else if (field === 'duration') {
      const [hours, minutes, seconds, frames] = durationInput.split(':').map(Number);
      startValue = {
        hours,
        minutes,
        seconds,
        frames
      };
    }

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = startY - currentY;
      const sensitivity = 0.1;

      if (field === 'duration') {
        const segment = Math.floor(touchX / (fieldWidth / 4)); // Split into 4 segments
        const framerate = parseFloat(calcData.framerate);
        
        // Calculate change based on segment
        let change = Math.round(diffY * sensitivity);
        let { hours, minutes, seconds, frames } = startValue;
        
        switch(segment) {
          case 0: // Hours
            hours = Math.max(0, hours - change);
            break;
          case 1: // Minutes
            minutes -= change;
            while (minutes < 0) {
              if (hours > 0) {
                hours--;
                minutes += 60;
              } else {
                minutes = 0;
              }
            }
            while (minutes >= 60) {
              hours++;
              minutes -= 60;
            }
            break;
          case 2: // Seconds
            seconds -= change;
            while (seconds < 0) {
              if (minutes > 0) {
                minutes--;
                seconds += 60;
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds += 60;
              } else {
                seconds = 0;
              }
            }
            while (seconds >= 60) {
              minutes++;
              seconds -= 60;
              if (minutes >= 60) {
                hours++;
                minutes -= 60;
              }
            }
            break;
          case 3: // Frames
            frames -= change;
            while (frames < 0) {
              if (seconds > 0) {
                seconds--;
                frames += Math.floor(framerate);
              } else if (minutes > 0) {
                minutes--;
                seconds = 59;
                frames += Math.floor(framerate);
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds = 59;
                frames += Math.floor(framerate);
              } else {
                frames = 0;
              }
            }
            while (frames >= framerate) {
              seconds++;
              frames -= Math.floor(framerate);
              if (seconds >= 60) {
                minutes++;
                seconds -= 60;
                if (minutes >= 60) {
                  hours++;
                  minutes -= 60;
                }
              }
            }
            break;
        }

        const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;

        setDurationInput(newDuration);
        setCalcData(prevData => ({
          ...prevData,
          duration: newDuration
        }));

        // Recalculate file size
        const { size, unit } = calculateFileSize({ ...calcData, duration: newDuration });
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size
        }));
        setFileSizeUnit(unit);
      }
      // ... existing fileSize handling ...
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [shootDays, calcData, fileSizeInput, durationInput, isFileSizeLocked, isDurationLocked, calculateFileSize, fileSizeUnit]);

  // Updated parseFileSize function to handle more input permutations
  const parseFileSize = (input) => {
    // Remove any whitespace and convert to lowercase
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();
  
    // Define unit patterns
    const unitPatterns = {
      'pb': ['p', 'ps', 'pb', 'pbs', 'pe', 'pet', 'pets', 'peta', 'petas', 'petabyte', 'petabytes'],
      'tb': ['t', 'ts', 'tb', 'tbs', 'te', 'ter', 'ters', 'tera', 'teras', 'terabyte', 'terabytes'],
      'gb': ['g', 'gs', 'gb', 'gbs', 'ge', 'gig', 'gigs', 'giga', 'gigas', 'gigabyte', 'gigabytes'],
      'mb': ['m', 'ms', 'mb', 'mbs', 'me', 'meg', 'megs', 'mega', 'megas', 'megabyte', 'megabytes'],
      'kb': ['k', 'ks', 'kb', 'kbs', 'ki', 'kil', 'kils', 'kilo', 'kilos', 'kilobyte', 'kilobytes'],
      'b': ['b', 'bs', 'bi', 'by', 'byte', 'bytes']
    };
  
    // Create a regex pattern that matches any of the unit patterns
    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    
    // Match the input against the pattern
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})$`));
  
    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2];
  
      // Determine the standardized unit
      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }
  
    return null;
  };

  // Update handleFileSizeFocus to clear the input and show placeholder text
  const handleFileSizeFocus = () => {
    if (!isFileSizeLocked) {
      setFileSizeInput('');
    }
  };

  // Updated handleFileSizeBlur function with correct syntax
  const handleFileSizeBlur = () => {
    if (fileSizeInput.trim() === '') {
      setFileSizeInput('');
      if (!isFileSizeLocked) {
        setCalcData(prevData => ({ ...prevData, fileSize: '' }));
      }
    } else {
      const parsedSize = parseFileSize(fileSizeInput);
      if (parsedSize !== null) {
        const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
        setFileSizeInput(formattedSize);
        if (!isFileSizeLocked) {
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size,
            fileSizeUnit: parsedSize.unit
          }));
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            }, formattedSize);
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
      } else {
        console.error("Invalid file size format");
        // Optionally, revert to previous valid input or notify the user
      }
    }
  };

  // Updated handleFileSizeKeyPress to trigger blur on Enter key
  const handleFileSizeKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      if (!isFileSizeLocked) {
        const parsedSize = parseFileSize(fileSizeInput);
        if (parsedSize !== null) {
          const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
          setFileSizeInput(formattedSize);
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size,
            fileSizeUnit: parsedSize.unit
          }));
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            }, formattedSize);
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
        e.target.blur(); // Remove focus from the input
      }
    }
  };

// Helper function to recalculate based on lock state
  const recalculateBasedOnLockState = (updatedResolution = calcData.resolution) => {
    const updatedCalcData = { ...calcData, resolution: updatedResolution };
    
    if (calculationMode === 'duration' && isDurationLocked) {
      // If duration is locked, recalculate file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    } else if (calculationMode === 'fileSize' && isFileSizeLocked) {
      // If file size is locked, recalculate duration based on file size
      const duration = calculateDuration(updatedCalcData, fileSizeInput);
      setDurationInput(duration);
      setCalcData(prevData => ({
        ...prevData,
        duration: duration
      }));
    } else {
      // If nothing is locked, default to recalculating file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    }
  };

   // Updated handleFileSizeWheel to implement proportional scrolling across all units
  const handleFileSizeWheel = (e) => {
    e.preventDefault();
    if (isFileSizeLocked || isDurationLocked) return;

    const delta = Math.sign(-e.deltaY); // Positive for scroll up, negative for scroll down
    const changeFactor = 1.1; // 10% change per scroll

    let parsed = parseFileSize(fileSizeInput);
    if (!parsed) return;
    let { size, unit } = parsed;
    let newSize = size;

    // Apply proportional change based on scroll direction
    if (delta > 0) {
      newSize *= changeFactor;
    } else {
      newSize /= changeFactor;
    }

    // Define the order of units
    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    // Adjust unit if size exceeds current unit's boundaries
    while (newSize >= 1000 && currentIndex < unitsOrder.length - 1) {
      newSize /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (newSize < 1 && currentIndex > 0) {
      newSize *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    // Format the new size based on unit for consistency
    newSize = unit === 'KB' ? Math.round(newSize) : parseFloat(newSize.toFixed(2));

    const newFileSizeInput = `${newSize} ${unit}`;
    setFileSizeInput(newFileSizeInput);

    // Update calcData immediately
    const updatedCalcData = {
      ...calcData,
      fileSize: newSize.toString(),
      fileSizeUnit: unit
    };
    setCalcData(updatedCalcData);

    // Recalculate duration based on the new file size
    const duration = calculateDuration(updatedCalcData, newFileSizeInput);
    setDurationInput(duration);
    setCalcData(prevData => ({
      ...prevData,
      duration: duration
    }));
  };

  // Ensure handleFileSizeWheel is correctly referenced in useEffect
  useEffect(() => {
    const fileSizeElement = fileSizeRef.current;

    const wheelHandler = (e) => {
      if (!isFileSizeLocked) {
        handleFileSizeWheel(e);
      }
    };

    if (fileSizeElement) {
      fileSizeElement.addEventListener('wheel', wheelHandler, { passive: false });
      fileSizeElement.addEventListener('touchmove', wheelHandler, { passive: false });
    }

    return () => {
      if (fileSizeElement) {
        fileSizeElement.removeEventListener('wheel', wheelHandler);
        fileSizeElement.removeEventListener('touchmove', wheelHandler);
      }
    };
  }, [isFileSizeLocked, handleFileSizeWheel]);

  // 3. Update the input fields in the JSX to apply styles based on lock state
  // Modify the useEffect for mobile anchoring
  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  

  useEffect(() => {
    setAvailableCameras(getCamerasByBrand(calcData.brand));
  }, [calcData.brand]);

  useEffect(() => {
    if (calcData.camera) {
      setAvailableResolutions(getResolutionsForCamera(calcData.camera));
      setAvailableFramerates(getFrameratesForCamera(calcData.camera));
    }
  }, [calcData.camera]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution) {
      setAvailableCodecs(getCodecsForCameraAndResolution(calcData.camera, calcData.resolution));
    }
  }, [calcData.camera, calcData.resolution]);

  

  useEffect(() => {
    const elements = [
      { ref: brandRef, handler: handleBrandWheel },
      { ref: cameraRef, handler: handleCameraWheel },
      { ref: resolutionRef, handler: handleResolutionWheel },
      { ref: framerateRef, handler: handleFramerateWheel },
      { ref: codecRef, handler: handleCodecWheel },
      { ref: durationRef, handler: handleDurationWheel },
      { ref: fileSizeRef, handler: handleFileSizeWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref.current) {
        ref.current.addEventListener('wheel', handler, { passive: false });
      }
    });

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref.current) {
          ref.current.removeEventListener('wheel', handler);
        }
      });
    };
  }, [calcData, handleBrandWheel, handleCameraWheel, handleResolutionWheel, 
    handleFramerateWheel, handleCodecWheel, handleDurationWheel, handleFileSizeWheel]);

  // Add these new refs
  const brandRef = useRef(null);
  const cameraRef = useRef(null);

  // Add these hooks for each dropdown
  const handleBrandTouch = useDropdownTouch(
    brands,
    calcData.brand,
    handleInputChange,
    'brand'
  );

  const handleCameraTouch = useDropdownTouch(
    availableCameras,
    calcData.camera,
    handleInputChange,
    'camera'
  );

  const handleResolutionTouch = useDropdownTouch(
    availableResolutions,
    calcData.resolution,
    handleInputChange,
    'resolution'
  );

  const handleCodecTouch = useDropdownTouch(
    availableCodecs,
    calcData.codec,
    handleInputChange,
    'codec'
  );

  const handleFramerateTouch = useDropdownTouch(
    availableFramerates,
    calcData.framerate,
    handleInputChange,
    'framerate'
  );

  // Add the calculateBitrate function inside the component
  const calculateBitrate = (data = calcData) => {
    try {
      // Get resolution dimensions
      let width, height;
      const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        [, width, height] = resolutionMatch;
      } else {
        throw new Error('Invalid resolution format');
      }

      // Get codec datarate
      const selectedCodec = codecs.find(codec => codec.name === data.codec);
      if (!selectedCodec) {
        throw new Error('Invalid codec');
      }
      const datarate = selectedCodec.datarate;

      // Calculate bitrate
      const framerate = parseFloat(data.framerate);
      const bitrateInBytes = (datarate * width * height * framerate) * 1024 * 1024;
      
      return formatBitrate(bitrateInBytes);
    } catch (error) {
      console.error('Error calculating bitrate:', error);
      return '0 MB/s';
    }
  };



  const logResult = () => {
    const logEntry = `AR: ${selectedRatio} - ${width}x${height}`;
    addLog(logEntry);
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);
  };

  const handleCopy = () => {
    setCopyButtonText(<SlLike />);
    setTimeout(() => setCopyButtonText(<SlDocs />), 500);
  };


  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);
  
  // Add this helper function to calculate GCD
  const getGCD = (a, b) => {
    a = Math.abs(a);
    b = Math.abs(b);
    while (b) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  };

  const formatRatio = (ratio) => {
    // Check if the input ratio matches any of the original custom ratios
    const matchingCustomRatio = customRatios.find(r => {
      const parsed = parseRatio(r);
      return Math.abs(parsed - ratio) < 0.0001;
    });
    
    if (matchingCustomRatio) {
      return matchingCustomRatio;
    }

    // For decimal inputs like 2.35, preserve as "2.35:1"
    if (Number.isFinite(ratio) && !Number.isInteger(ratio)) {
      return `${ratio.toFixed(2)}:1`;
    }

    // For integer ratios, use the GCD reduction
    const tolerance = 0.0001;
    for (let denominator = 1; denominator <= 16; denominator++) {
      const numerator = ratio * denominator;
      if (Math.abs(numerator - Math.round(numerator)) < tolerance) {
        let n = Math.round(numerator);
        let d = denominator;
        const gcd = getGCD(n, d);
        n = n / gcd;
        d = d / gcd;
        return `${n}:${d}`;
      }
    }

    return `${ratio.toFixed(2)}:1`;
  };



  // Update recalculateBasedOnRatio to respect locks
  const recalculateBasedOnRatio = (ratio) => {
    const aspectRatio = parseRatio(ratio);
    if (!isNaN(aspectRatio)) {
      if (lockedField === 'width') {
        // Width is locked - only update height
        const newHeight = roundToEven(width / aspectRatio);
        setHeight(newHeight);
        setDailiesData(prev => ({ ...prev, height: newHeight }));
      } else if (lockedField === 'height') {
        // Height is locked - only update width
        const newWidth = roundToEven(height * aspectRatio);
        setWidth(newWidth);
        setDailiesData(prev => ({ ...prev, width: newWidth }));
      } else {
        // Nothing is locked - maintain width and update height
        const newHeight = roundToEven(width / aspectRatio);
        setHeight(newHeight);
        setDailiesData(prev => ({ ...prev, height: newHeight }));
      }
    }
  };
  
  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  
  
  const handleRatioWheel = (e) => {
    e.preventDefault();
    if (lockedField) return;

    // Increase sensitivity for faster response
    const sensitivity = 0.05; // Increased from 0.025
    const scrollAmount = -e.deltaY * sensitivity;
    
    const ratios = [
      "Match",
      ...defaultAspectRatios
        .filter(r => r !== "Match" && r !== "Custom"),
      ...customRatios
    ];

    const currentIndex = ratios.indexOf(selectedRatio);
    const delta = Math.sign(scrollAmount);
    let newIndex = currentIndex + delta;
    newIndex = Math.max(0, Math.min(newIndex, ratios.length - 1));
    
    if (newIndex !== currentIndex) {
      const newRatio = ratios[newIndex];
      setSelectedRatio(newRatio);
      updateDimensionsForRatio(newRatio);
    }
  };

  // Add new helper function to handle dimension updates
  const updateDimensionsForRatio = (ratio) => {
    if (ratio === 'Match') {
      const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        const newHeight = roundToEven(dailiesData.width / cameraAspect);
        
        setHeight(newHeight);
        setDailiesData(prev => ({
          ...prev,
          height: newHeight,
          aspectRatio: 'Match'
        }));

        if (!isDailiesFileSizeLocked) {
          calculateDailiesFileSize();
        }
      }
    } else {
      recalculateBasedOnRatio(ratio);
    }
  };

  // Update the useEffect for the ratio wheel event listener
  useEffect(() => {
    const ratioElement = ratioRef.current;
    
    if (ratioElement) {
      const wheelHandler = (e) => {
        if (document.activeElement !== ratioElement) {
          handleRatioWheel(e);
        }
      };
      
      ratioElement.addEventListener('wheel', wheelHandler, { passive: false });
      return () => ratioElement.removeEventListener('wheel', wheelHandler);
    }
  }, [selectedRatio, calcData.resolution, width]);

  // Add handleCustomRatioKeyDown if it's not already defined
  const handleCustomRatioKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      processCustomRatio();
      // Move focus to the next element
      widthRef.current?.focus();
    }
  };

  // First, add new state variables near the top with other state declarations
  const [dailiesCodec, setDailiesCodec] = useState('ProRes 422 HQ');
  const [dailiesFileSize, setDailiesFileSize] = useState('');
  const [isDailiesFileSizeLocked, setIsDailiesFileSizeLocked] = useState(false);

  // Update the handleDailiesFileSizeLockClick function
  const handleDailiesFileSizeLockClick = () => {
    if (calculationMode === 'dailiesFileSize') {
      // Unlock all if dailies file size is already locked
      setIsDurationLocked(false);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Lock dailies file size and unlock others
      setIsDurationLocked(false);
      setIsFileSizeLocked(false);
      setIsDailiesFileSizeLocked(true);
      setCalculationMode('dailiesFileSize');
    }
  };


  // Add state for dailies aspect ratio handling
  const [customDailiesRatio, setCustomDailiesRatio] = useState("");
  const [customDailiesRatios, setCustomDailiesRatios] = useState([]);

  // Add dailies-specific state variables
  const [availableDailiesCodecs] = useState(dailiesCodecs.map(codec => codec.name));

  // Update handleMatchAspectRatio to use the same logic
  const handleMatchAspectRatio = useCallback(() => {
    if (calcData.resolution) {
      const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, cameraWidth, cameraHeight] = resolutionMatch;
        const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
        
        if (lockedField === 'width') {
          // Width is locked - update height
          const newHeight = roundToEven(width / cameraAspect);
          setHeight(newHeight);
          setDailiesData(prev => ({ ...prev, height: newHeight }));
        } else if (lockedField === 'height') {
          // Height is locked - update width
          const newWidth = roundToEven(height * cameraAspect);
          setWidth(newWidth);
          setDailiesData(prev => ({ ...prev, width: newWidth }));
        } else {
          // Nothing is locked - maintain width and update height
          const newHeight = roundToEven(width / cameraAspect);
          setHeight(newHeight);
          setDailiesData(prev => ({ ...prev, height: newHeight }));
        }

        // Update dailies file size calculations
        if (!isDailiesFileSizeLocked) {
          calculateDailiesFileSize(footagePerDay, true);
          const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
          const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
          calculateDailiesFileSize(totalDuration, false);
        }
      }
    }
  }, [calcData.resolution, width, height, lockedField, footagePerDay, shootDays, calcData.framerate, isDailiesFileSizeLocked]);

  // Add effect to handle aspect ratio matching when camera resolution changes
  useEffect(() => {
    handleMatchAspectRatio();
  }, [calcData.resolution, handleMatchAspectRatio]);


  // Add an effect to trigger dailies file size calculation when relevant values change
  useEffect(() => {
    if (!isDailiesFileSizeLocked) {
      calculateDailiesFileSize();
    }
  }, [
    calcData.duration,
    calcData.framerate,
    dailiesData.width,
    dailiesData.height,
    dailiesData.codec,
    isDailiesFileSizeLocked,
    calculateDailiesFileSize
  ]);

  // Update the useEffect for camera resolution changes
  useEffect(() => {
    if (calcData.camera) {
      const resolutions = getResolutionsForCamera(calcData.camera);
      setAvailableResolutions(resolutions);
      
      if (!resolutions.includes(calcData.resolution)) {
        const newResolution = resolutions[0] || '';
        const newCalcData = { 
          ...calcData, 
          resolution: newResolution,
          codec: '',
          framerate: ''
        };
        setCalcData(newCalcData);
        recalculateFileSizes(newCalcData);
      }

      // Update dailies dimensions based on camera resolution
      if (dailiesData.aspectRatio === 'Match') {
        const resolutionMatch = calcData.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
        if (resolutionMatch) {
          const [, cameraWidth, cameraHeight] = resolutionMatch;
          const cameraAspect = parseInt(cameraWidth) / parseInt(cameraHeight);
          
          // Keep the dailies width and adjust height to match camera aspect ratio
          const newHeight = roundToEven(dailiesData.width / cameraAspect);
          setDailiesData(prev => ({
            ...prev,
            height: newHeight
          }));
          setHeight(newHeight); // Also update the height state

          // Recalculate dailies file size with new dimensions
          if (!isDailiesFileSizeLocked) {
            calculateDailiesFileSize();
          }
        }
      }
    }
  }, [calcData.camera, calcData.resolution, dailiesData.width, dailiesData.aspectRatio, isDailiesFileSizeLocked]);



  // Add these new handlers
  const handleDailiesCodecWheel = (e) => {
    handleWheel(
      e,
      availableDailiesCodecs,
      dailiesData.codec,
      (newCodec) => {
        setDailiesData(prev => ({ ...prev, codec: newCodec }));
        
        // Calculate dailies per day with new codec
        calculateDailiesFileSize(footagePerDay, true);
        
        // Calculate total dailies based on shoot days
        const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
        const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
        calculateDailiesFileSize(totalDuration, false);
      }
    );
  };

  // Add handler for dailies ratio wheel
  const handleDailiesRatioWheel = (e) => {
    if (lockedField) return;

    // Create ratios array without Custom, and ensure Match is at the top
    const ratios = [
      "Match",
      ...defaultAspectRatios
        .filter(r => r !== "Match" && r !== "Custom")
        .sort(compareRatios),
      ...customRatios.sort(compareRatios)
    ];

    const currentIndex = ratios.indexOf(selectedRatio);
    const delta = Math.sign(-e.deltaY);
    let newIndex = currentIndex + delta;
    newIndex = Math.max(0, Math.min(newIndex, ratios.length - 1));
    
    if (newIndex !== currentIndex) {
      const newRatio = ratios[newIndex];
      setSelectedRatio(newRatio);
      recalculateBasedOnRatio(newRatio);
    }
  };

  // Update the handleDailiesFileSizeWheel function
  const handleDailiesFileSizeWheel = (e) => {
    e.preventDefault();
    if (isDailiesFileSizeLocked) return;

    const delta = Math.sign(-e.deltaY);
    const changeFactor = 1.1;

    let parsed = parseFileSize(dailiesFileSize);
    if (!parsed) return;
    let { size, unit } = parsed;
    let newSize = size;

    if (delta > 0) {
      newSize *= changeFactor;
    } else {
      newSize /= changeFactor;
    }

    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    while (newSize >= 1000 && currentIndex < unitsOrder.length - 1) {
      newSize /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (newSize < 1 && currentIndex > 0) {
      newSize *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    newSize = unit === 'KB' ? Math.round(newSize) : parseFloat(newSize.toFixed(2));
    const newDailiesFileSize = `${newSize} ${unit}`;
    setDailiesFileSize(newDailiesFileSize);
    setDailiesData(prev => ({
      ...prev,
      fileSize: newDailiesFileSize
    }));

    // Recalculate camera file size and duration based on dailies size
    recalculateFromDailiesSize(newSize, unit);
  };

  // Add new function to handle recalculation from dailies size
  const recalculateFromDailiesSize = (dailiesSize, dailiesUnit) => {
    if (isDurationLocked || isFileSizeLocked) return;

    // Convert dailies size to bytes
    const unitMultiplier = {
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024
    };
    
    const dailiesSizeInBytes = dailiesSize * unitMultiplier[dailiesUnit];

    // Get dailies codec datarate
    const dailiesCodecData = dailiesCodecs.find(codec => codec.name === dailiesData.codec);
    if (!dailiesCodecData) return;

    // Calculate frames based on dailies size
    const framerate = parseFloat(calcData.framerate);
    const dailiesPixels = width * height;
    
    // Convert codec datarate from Mbps to bytes per frame
    const mbitsPerSecond = dailiesCodecData.datarate;
    const bytesPerSecond = (mbitsPerSecond * 1024 * 1024) / 8;
    const bytesPerFrame = bytesPerSecond / framerate;
    
    // Calculate total frames
    const totalFrames = Math.round(dailiesSizeInBytes / bytesPerFrame);
    
    // Convert frames to timecode
    const newDuration = Timecode.fromFrames(totalFrames, framerate).toString();

    // Update duration
    setDurationInput(newDuration);
    setCalcData(prev => ({
      ...prev,
      duration: newDuration
    }));

    // Calculate camera file size based on new duration
    const { size: cameraSize, unit: cameraUnit } = calculateFileSize({
      ...calcData,
      duration: newDuration
    });

    // Update camera file size
    setFileSizeInput(`${cameraSize} ${cameraUnit}`);
    setFileSizeUnit(cameraUnit);
  };

  // Update the dailies file size input handler
  const handleDailiesFileSizeChange = (e) => {
    const newValue = e.target.value;
    setDailiesFileSize(newValue);
    
    // Parse and validate the new value
    const parsed = parseFileSize(newValue);
    if (parsed) {
      setDailiesData(prev => ({
        ...prev,
        fileSize: `${parsed.size} ${parsed.unit}`
      }));
      recalculateFromDailiesSize(parsed.size, parsed.unit);
    }
  };

  // Add blur handler for dailies file size input
  const handleDailiesFileSizeBlur = () => {
    const parsed = parseFileSize(dailiesFileSize);
    if (parsed) {
      const formattedSize = `${parsed.size} ${parsed.unit}`;
      setDailiesFileSize(formattedSize);
      setDailiesData(prev => ({
        ...prev,
        fileSize: formattedSize
      }));
      recalculateFromDailiesSize(parsed.size, parsed.unit);
    }
  };

  // Add touch handlers for mobile scrolling
  const handleDailiesWidthTouch = (e) => {
    if (lockedField) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let lastY = startY;
    
    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = lastY - currentY;
      const isLeftHalf = touchX < fieldWidth / 2;
      const baseChange = isLeftHalf ? 20 : 2;
      const changeAmount = Math.abs(diffY) > 5 
        ? baseChange * Math.abs(diffY / 5) 
        : baseChange;
      
      const delta = Math.sign(diffY);
      const newWidth = roundToEven(Math.max(2, dailiesData.width + delta * changeAmount));
      handleWidthChange({ target: { value: newWidth } });
      lastY = currentY;
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleDailiesHeightTouch = (e) => {
    if (lockedField) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let lastY = startY;
    
    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = lastY - currentY;
      const isLeftHalf = touchX < fieldWidth / 2;
      const baseChange = isLeftHalf ? 20 : 2;
      const changeAmount = Math.abs(diffY) > 5 
        ? baseChange * Math.abs(diffY / 5) 
        : baseChange;
      
      const delta = Math.sign(diffY);
      const newHeight = roundToEven(Math.max(2, dailiesData.height + delta * changeAmount));
      handleHeightChange({ target: { value: newHeight } });
      lastY = currentY;
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  };

  // Add useEffect for wheel event listeners
  useEffect(() => {
    const elements = [
      { ref: ratioRef, handler: handleDailiesRatioWheel },
      { ref: dailiesCodecRef, handler: handleDailiesCodecWheel },
      { ref: dailiesFileSizeRef, handler: handleDailiesFileSizeWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref?.current) {
        ref.current.addEventListener('wheel', handler, { passive: false });
        return () => ref.current?.removeEventListener('wheel', handler);
      }
    });
  }, [selectedRatio, dailiesData.codec, dailiesFileSize]); // Add missing dependency array and closing bracket

  // Add this effect to ensure calculator stays at top after keyboard appears/disappears on mobile
  useEffect(() => {
    if (isMobile) {
      const handleResize = () => {
        window.scrollTo(0, 0);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [isMobile]);

  const handleDailiesCodecTouch = useDropdownTouch(
    dailiesCodecs.map(codec => codec.name),
    dailiesData.codec,
    (e) => {
      const newCodec = e.target.value;
      setDailiesData(prev => ({ ...prev, codec: newCodec }));
      
      // Calculate dailies per day with new codec
      calculateDailiesFileSize(footagePerDay, true);
      
      // Calculate total dailies based on shoot days
      const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
      const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
      calculateDailiesFileSize(totalDuration, false);
    },
    'dailiesCodec'
  );

  const handleDailiesRatioTouch = useDropdownTouch(
    [...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios],
    selectedRatio,
    handleRatioChange,
    'ratio'
  );

  

  // Move handleShootDaysChange before handleTouchStart and wrap it in useCallback
  const handleShootDaysChange = useCallback((e) => {
    const value = Math.max(1, parseInt(e.target.value) || 1);
    setShootDays(value);
    recalculateFileSizes(calcData, footagePerDay, value);
  }, [calcData, footagePerDay, recalculateFileSizes]);

  // Add wheel handler for shoot days
  const handleShootDaysWheel = useCallback((e) => {
    e.preventDefault();
    const delta = Math.sign(-e.deltaY);
    const newValue = Math.max(1, shootDays + delta);
    if (newValue !== shootDays) {
      setShootDays(newValue);
      handleShootDaysChange({ target: { value: newValue } });
    }
  }, [shootDays, handleShootDaysChange]);

  // Update handleTouchStart to use a ref for handleShootDaysChange
  useEffect(() => {
    handleShootDaysChangeRef.current = handleShootDaysChange;
  }, [handleShootDaysChange]);

  // Add useEffect to calculate initial values
  useEffect(() => {
    // Calculate initial values when component mounts
    const initialCalculation = () => {
      // Calculate total footage
      const totalFrames = Timecode.parse(footagePerDay, parseFloat(calcData.framerate)).totalFrames() * shootDays;
      const totalDuration = Timecode.fromFrames(totalFrames, parseFloat(calcData.framerate)).toString();
      setDurationInput(totalDuration);
      
      // Calculate file size per day
      const { size: perDaySize, unit: perDayUnit } = calculateFileSize({ 
        ...calcData, 
        duration: footagePerDay 
      });
      setFileSizePerDay(`${perDaySize} ${perDayUnit}`);
      
      // Calculate total OCF file size
      const { size, unit } = calculateFileSize({ 
        ...calcData, 
        duration: totalDuration 
      });
      setFileSizeInput(`${size} ${unit}`);
      setFileSizeUnit(unit);

      // Calculate dailies sizes
      calculateDailiesFileSize(footagePerDay, true);
      calculateDailiesFileSize(totalDuration, false);
    };

    initialCalculation();
  }, []); // Run once on mount

  const handleFootagePerDayChange = (e) => {
    const newFootagePerDay = e.target.value;
    setFootagePerDay(newFootagePerDay);
    recalculateFileSizes(calcData, newFootagePerDay, shootDays);
  };

  const currentSpacing = isMobile ? spacing.mobile : spacing.desktop;

  // Use global styles
  const commonInputClass = commonStyles.commonInputClass;
  const textCenterImportant = commonStyles.textCenterImportant;
  const getInputStyle = (field, mode) => {
    return commonStyles.getInputStyle(field, mode);
  };

  // Add these handlers
  const handleWidthFocus = (e) => {
    if (lockedField !== 'width') {
      e.target.value = '';
    }
  };

  const handleHeightFocus = (e) => {
    if (lockedField !== 'height') {
      e.target.value = '';
    }
  };

  // Add state for tracking focus
  const [focusedField, setFocusedField] = useState(null);

  return (
    <div className={`dailies-calculator ${currentSpacing.padding} ${colors.bgColor} ${colors.textColor}`}>
      <div className={layout.flex.column}>
        {/* Row 1 - Shoot Days, Footage per Day, Total Footage */}
        <div className={`grid ${layout.grid.daysLayout} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={commonStyles.labelClass}>Footage / Day</label>
            <input
              ref={durationRef}
              type="text"
              value={footagePerDay}
              onChange={handleFootagePerDayChange}
              onFocus={handleDurationFocus}
              onBlur={handleDurationBlur}
              onKeyPress={handleDurationKeyPress}
              onWheel={handleDurationWheel}
              onTouchStart={(e) => handleTouchStart(e, 'duration')}
              placeholder="ex: 01:00:00:00 or 16 hrs"
              className={getInputStyle('duration')}
            />
          </div>
          <div>
            <label className={commonStyles.labelClass}>Days</label>
            <input
              type="number"
              min="1"
              value={shootDays}
              onChange={handleShootDaysChange}
              onWheel={handleShootDaysWheel}
              onTouchStart={(e) => handleTouchStart(e, 'shootDays')}
              className={commonStyles.commonInputClass}
            />
          </div>
          <div>
            <label className={commonStyles.labelClass}>Total Duration</label>
            <input
              type="text"
              value={durationInput}
              className={`${commonInputClass} bg-gray-100`}
              readOnly
              aria-readonly="true"
            />
          </div>
        </div>

        {/* Row 2 - Brand and Camera */}
        <div className={`grid ${layout.grid.brandCamera} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={commonStyles.labelClass}>Brand</label>
            <select
              ref={brandRef}
              name="brand"
              value={calcData.brand}
              onChange={handleInputChange}
              onTouchStart={handleBrandTouch}
              className={commonStyles.commonInputClass}
            >
              {brands.map(brand => (
                <option key={brand} value={brand}>{brand}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={commonStyles.labelClass}>Camera</label>
            <select
              ref={cameraRef}
              name="camera"
              value={calcData.camera}
              onChange={handleInputChange}
              onTouchStart={handleCameraTouch}
              className={commonStyles.commonInputClass}
            >
              {availableCameras.map(camera => (
                <option key={camera} value={camera}>{camera}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Row 3 - Resolution and Framerate */}
        <div className={`grid ${layout.grid.resolutionFramerate} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={commonStyles.labelClass}>Resolution</label>
            <select
              ref={resolutionRef}
              name="resolution"
              value={calcData.resolution}
              onChange={handleInputChange}
              onTouchStart={handleResolutionTouch}
              className={commonStyles.commonInputClass}
            >
              {availableResolutions.map(resolution => (
                <option key={resolution} value={resolution}>{resolution}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={commonStyles.labelClass}>Framerate</label>
            <select
              ref={framerateRef}
              name="framerate"
              value={calcData.framerate}
              onChange={handleInputChange}
              onTouchStart={handleFramerateTouch}
              className={commonStyles.commonInputClass}
            >
              {availableFramerates.map(framerate => (
                <option key={framerate} value={framerate}>{framerate}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Row 4 - Codec and Bitrate */}
        <div className={`grid ${layout.grid.codecBitrate} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={commonStyles.labelClass}>Codec</label>
            <select
              ref={codecRef}
              name="codec"
              value={calcData.codec}
              onChange={handleInputChange}
              onTouchStart={handleCodecTouch}
              className={commonStyles.commonInputClass}
            >
              {availableCodecs.map(codec => (
                <option key={codec} value={codec}>{codec}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={commonStyles.labelClass}>Bitrate</label>
            <input
              type="text"
              value={calculateBitrate()}
              className={`${commonInputClass} bg-gray-100`}
              readOnly
            />
          </div>
        </div>

        {/* Row 5 - File Sizes */}
        <div className={`grid ${layout.grid.twoColumns} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={commonStyles.labelClass}>File Size / Day</label>
            <input
              type="text"
              value={fileSizePerDay}
              className={`${commonInputClass} bg-gray-100`}
              readOnly
            />
          </div>
          <div>
            <label className={commonStyles.labelClass}>Total OCF File Size</label>
            <input
              type="text"
              value={fileSizeInput}
              className={`${commonInputClass} bg-gray-100`}
              readOnly
            />
          </div>
        </div>

        {/* Row 6 - Dailies Aspect, Width, Lock, Height */}
        <div className={`grid ${layout.grid.dailesAspectRatio} gap-2 ${currentSpacing.fieldGap} items-end`}>
          <div>
            <label className={commonStyles.labelClass}>Dailies Aspect</label>
            <select
              ref={ratioRef}
              value={selectedRatio}
              onChange={handleRatioChange}
              onTouchStart={handleDailiesRatioTouch}
              className={commonStyles.commonInputClass}
            >
              {[...defaultAspectRatios.filter(r => r !== "Custom"), ...customRatios]
                .map(ratio => (
                  <option key={ratio} value={ratio}>{ratio}</option>
            ))}
            </select>
          </div>
          <div>
            <label className={commonStyles.labelClass}>Width</label>
            <input
              ref={widthRef}
              type="text"
              name="width"
              value={width}
              onChange={handleWidthChange}
              onBlur={(e) => {
                setFocusedField(null);
                handleWidthBlur(e);
              }}
              onFocus={(e) => {
                setFocusedField('width');
                if (lockedField === 'height') {
                  e.target.select();
                }
              }}
              onKeyPress={handleWidthKeyPress}
              onWheel={handleWidthWheel}
              onClick={(e) => {
                e.target.select();
                setFocusedField('width');
              }}
              placeholder={lockedField === 'height' && focusedField === 'width' ? 'Enter width to set ratio' : ''}
              className={commonStyles.getInputStyle('width', lockedField)}
              disabled={lockedField === 'width'}
              onTouchStart={(e) => handleTouchStart(e, 'width')}
              onTouchMove={(e) => handleTouchMove(e, 'width')}
            />
          </div>
          <div className="flex flex-col justify-end">
            <label className="block invisible">Lock</label>
            <button
              type="button"
              onClick={handleLockClick}
              className={`${commonStyles.lockButtonStyle(lockedField)} relative`}
            >
              {lockedField ? (
                <FaLock className={`${colors.lockIconColor} transform scale-75`} />
              ) : (
                <FaUnlockAlt className={colors.unlockIconColor} />
              )}
              {lockedField === 'width' && (
                <FaAngleLeft className={`absolute -left-1 top-1/2 -translate-y-1/2 ${colors.arrowColor} text-lg`} />
              )}
              {lockedField === 'height' && (
                <FaAngleRight className={`absolute -right-1 top-1/2 -translate-y-1/2 ${colors.arrowColor} text-lg`} />
              )}
            </button>
          </div>
          <div>
            <label className={commonStyles.labelClass}>Height</label>
            <input
              ref={heightRef}
              type="text"
              name="height"
              value={height}
              onChange={handleHeightChange}
              onBlur={(e) => {
                setFocusedField(null);
                handleHeightBlur(e);
              }}
              onFocus={(e) => {
                setFocusedField('height');
                if (lockedField === 'width') {
                  e.target.select();
                }
              }}
              onKeyPress={handleHeightKeyPress}
              onWheel={handleHeightWheel}
              onClick={(e) => {
                e.target.select();
                setFocusedField('height');
              }}
              placeholder={lockedField === 'width' && focusedField === 'height' ? 'Enter height to set ratio' : ''}
              className={commonStyles.getInputStyle('height', lockedField)}
              disabled={lockedField === 'height'}
              onTouchStart={(e) => handleTouchStart(e, 'height')}
              onTouchMove={(e) => handleTouchMove(e, 'height')}
            />
          </div>
        </div>

        {/* Row 7 - Dailies Codec and File Sizes */}
        <div className={`grid ${layout.grid.threeColumns} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={commonStyles.labelClass}>Dailies Codec</label>
            <select
              ref={dailiesCodecRef}
              value={dailiesData.codec}
              onChange={handleDailiesCodecChange}
              onTouchStart={handleDailiesCodecTouch}
              className={commonStyles.commonInputClass}
            >
              {dailiesCodecs.map(codec => (
                <option key={codec.name} value={codec.name}>{codec.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={commonStyles.labelClass}>Dailies / Day</label>
            <input
              type="text"
              value={dailiesFileSizePerDay}
              className={`${commonInputClass} bg-gray-100`}
              readOnly
              aria-readonly="true"
            />
          </div>
          <div>
            <label className={commonStyles.labelClass}>Total Dailies</label>
            <input
              type="text"
              value={totalDailiesFileSize}
              className={`${commonInputClass} bg-gray-100`}
              readOnly
              aria-readonly="true"
            />
          </div>
        </div>

        {/* Action Buttons */}
        <CalculatorButtons 
          onLog={handleLogClick}
          onShare={handleShare}
          onReset={handleReset}
          copyText={`${calcData.brand} ${calcData.camera} - ${calcData.resolution} \n${calcData.codec} @ ${calcData.framerate}fps (${calculateBitrate()}) \n${calcData.duration} = ${fileSizeInput}\r\n`}
          logButtonIcon={logButtonText}
          copyButtonIcon={copyDurationText}
          shareButtonIcon={shareButtonText}
          resetButtonIcon={resetButtonText}
        />
      </div>
    </div>
  );
};

export default dailiesCalculator;