// Global style constants and utility functions
export const colors = {
  labelColor: 'text-gray-700',
  bgColor: 'bg-white',
  inputBgColor: 'bg-gray-50',
  lockedBgColor: 'bg-gray-200',
  affectedBgColor: 'bg-gray-50',
  readOnlyBgColor: 'bg-gray-100',
  inputBorderColor: 'border-gray-300',
  affectedBorderColor: 'border-gray-300',
  lockedBorderColor: 'border-blue-600',
  textColor: 'text-gray-900',
  buttonColor: 'bg-blue-600 hover:bg-blue-500',
  buttonBorderColor: 'border-blue-600',
  arrowColor: 'text-blue-600',
  buttonShadow: 'shadow-md hover:shadow-lg',
  lockButtonBgColor: 'bg-gray-200 hover:bg-gray-300',
  lockButtonActiveBgColor: 'bg-gray-300',
  lockIconColor: 'text-blue-600',
  unlockIconColor: 'text-gray-500',
};

export const spacing = {
  desktop: {
    rowGap: 'space-y-2',
    sectionGap: 'space-y-4',
    fieldGap: 'mb-1',
    padding: 'p-1',
    margin: 'm-4',
    gridGap: 'gap-2',
    headerPadding: 'px-4 py-2',
    contentPadding: 'p-5',
  },
  mobile: {
    rowGap: 'space-y-3',
    sectionGap: 'space-y-4',
    fieldGap: 'mb-1',
    padding: 'p-1',
    margin: 'm-2',
    gridGap: 'gap-2',
    headerPadding: 'px-2 py-1',
    contentPadding: 'p-2',
  }
};

export const layout = {
  grid: {
    twoColumns: 'grid-cols-2',
    threeColumns: 'grid-cols-3',
    fourColumns: 'grid-cols-4',
    lockLayout: 'grid-cols-[1fr,auto,1fr]',
    daysLayout: 'grid-cols-[1fr,100px,1fr]',
    brandCamera: 'grid-cols-[1fr,2fr]',
    codecBitrate: 'grid-cols-[2fr,1fr]',
    resolutionFramerate: 'grid-cols-[2fr,1fr]',
    dailesAspectRatio: 'grid-cols-[1fr,1fr,auto,1fr]'
  },
  flex: {
    standard: 'flex items-center',
    between: 'flex justify-between',
    column: 'flex flex-col',
    wrap: 'flex flex-wrap'
  }
};

export const typography = {
  desktop: {
    label: 'text-sm font-medium',
    input: 'text-base',
    heading: 'text-lg font-semibold'
  },
  mobile: {
    label: 'text-xs font-medium',
    input: 'text-base',
    heading: 'text-base font-semibold'
  }
};

// First, let's define a base input style string
const baseInputStyle = `
  w-full 
  px-2 
  py-1 
  border 
  rounded 
  h-[36px] 
  font-['Roboto']
  text-center
  text-sm
  md:text-base
`;

export const commonStyles = {
  // Common input class with all necessary styles
  commonInputClass: `
    ${baseInputStyle}
    ${colors.inputBgColor} 
    ${colors.inputBorderColor} 
    ${colors.textColor}
    [&>option]:text-sm
    [&>option]:md:text-base
    [&>option]:text-center
    [&>option]:!text-center
    [-webkit-appearance:none]
    [appearance:none]
    text-align: center !important
    -moz-text-align: center !important
    -webkit-text-align: center !important
    text-align-last: center !important
    -moz-text-align-last: center !important
    -webkit-text-align-last: center !important
  `,

  // Lock button style
  lockButtonStyle: (isActive) => `p-2 rounded h-[36px] w-[42px] flex items-center justify-center self-end ${colors.lockButtonBgColor} border-2 ${colors.buttonShadow} ${
    isActive ? 'border-blue-600' : 'border-gray-300'
  }`,

  // Base style for inputs
  baseStyle: "w-full px-2 py-1 border rounded h-[36px] appearance-none",

  // Function to get input style based on lock state
  getInputStyle: (field, calculationMode) => {
    if (calculationMode === field) {
      // Locked field style
      return `${baseInputStyle} 
        ${colors.lockedBgColor} 
        border-2 
        ${colors.lockedBorderColor} 
        text-gray-700 
        cursor-not-allowed 
        pointer-events-none`;
    } else if (calculationMode) {
      // Other fields when something is locked
      return `${baseInputStyle} 
        ${colors.affectedBgColor} 
        border-[1.5px] 
        ${colors.affectedBorderColor} 
        text-gray-700`;
    }
    
    // Default style when nothing is locked
    return `${baseInputStyle} 
      ${colors.inputBgColor} 
      ${colors.inputBorderColor} 
      ${colors.textColor}`;
  },

  // Text center styles for mobile
  textCenterImportant: {
    textAlign: 'center !important',
    textAlignLast: 'center !important',
    '-moz-text-align-last': 'center !important',
    '-webkit-text-align-last': 'center !important'
  },

  // Update the actionButtons styles
  actionButtons: {
    container: "mt-4 grid grid-cols-3 sm:grid-cols-4 w-full gap-2 sm:flex sm:justify-between",
    button: (color = colors.buttonColor) => `
      px-2 sm:px-4 rounded-md text-white 
      flex items-center justify-center 
      w-full
      sm:min-w-[100px]
      sm:flex-1
      py-2
      ${color} ${colors.buttonShadow}
    `,
    buttonIcon: "",
    buttonText: "text-sm sm:text-base font-light whitespace-nowrap",
    copyButton: "hidden sm:flex",
    spacing: {
      first: "",
      middle: "",
      last: ""
    }
  },

  // Read-only input style
  readOnlyInput: `${colors.readOnlyBgColor} cursor-default`,

  // Add labelClass for consistent label styling
  labelClass: `
    block 
    text-gray-700 
    text-sm 
    font-medium 
    font-['Roboto']
    sm:text-xs 
    md:text-base
    text-left
  `
};

export const breakpoints = {
  mobile: 767,    // Up to tablets
  tablet: 1024,   // Up to small desktop
  desktop: 1025   // Desktop and above
};

export const deviceTypes = {
  isMobile: () => window.innerWidth <= breakpoints.mobile,
  isTablet: () => {
    const width = window.innerWidth;
    return width > breakpoints.mobile && width <= breakpoints.tablet;
  },
  isTouchDevice: () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
  isDesktop: () => window.innerWidth > breakpoints.tablet
};

export const headerStyles = {
  container: (isMobile) => `
    flex items-center justify-between 
    ${isMobile ? 'px-2 py-1' : 'px-4 py-2'}
    bg-white shadow-md
  `,
  title: (isMobile) => `
    ${isMobile ? 'text-sm hidden sm:block' : 'text-lg'} 
    font-bold text-gray-800
  `,
  iconContainer: `
    flex items-center space-x-2
    sm:space-x-3
    md:space-x-4
  `,
  icon: (isMobile) => `
    ${isMobile ? 'w-8 h-8' : 'w-10 h-10'}
    p-1.5
    rounded-full
    hover:bg-gray-100
    transition-colors
    duration-200
  `,
  select: (isCompactView) => `
    text-${isCompactView ? 'lg' : 'xl'} 
    font-bold 
    bg-white 
    border-none 
    focus:outline-none 
    header-select
    w-full 
    ${isCompactView ? 'compact-select' : ''} 
    text-right
    rtl
    appearance-none
    pr-8
    pl-4
  `,
  compactContainer: `
    flex 
    flex-col 
    justify-between 
    flex-grow 
    pl-4
  `,
  iconContainer: `
    flex-1 
    flex 
    items-center 
    justify-end
  `,
  iconGrid: `
    w-full 
    flex 
    justify-between 
    items-center 
    px-2
  `,
  icon: (isActive) => `
    transition-colors 
    flex 
    justify-center
    ${isActive ? 'text-blue-600' : 'text-gray-400'}
  `
}; 



// dark-bg, .dark-mode-texts, body[data-theme=dark] {
//   --bg: #0E1019;
//   --bg-2: rgba(255, 255, 255, 0.01);
//   --bg-3: #0E1019;
//   --bg-4: #0E1019;
//   --bg-5: #0E1019;
//   --bg-6: #0E1019;
//   --bg-7: #13151C;
//   --bg-8: #0E1019;
//   --color-headings: #fff;
//   --color-headings-opacity: rgba(255, 255, 255, 0.4);
//   --color-texts-opacity: rgba(255, 255, 255, 0.7);
//   --color-texts: #fff;
//   --btn-border: rgba(255, 255, 255, .3);
//   --border-color: rgba(255, 255, 255, .08);
//   --border-color-2: rgba(255, 255, 255, .08);
//   --force-dark: #171a23;
// }