import React from 'react';

const ComingSoon = () => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-2">
        <a 
          href="https://forms.gle/UqCj9QU1gTePgoiD8" 
          className="w-full px-4 py-2 text-sm text-center text-blue-600 bg-blue-50 border border-blue-200 rounded hover:bg-blue-100 transition-colors duration-200"
          target="_blank" 
          rel="noopener noreferrer"
        >
          Drop us a line with questions or suggestions!
        </a>
      </div>
    </div>
  );
};

export default ComingSoon; 