export const handleShareOnDevice = async (title, text, url) => {
  // First try native sharing
  if (navigator.share && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try {
      await navigator.share({
        title,
        text,
        url
      });
      return { success: true, method: 'share' };
    } catch (error) {
      console.error('Error sharing:', error);
      // If share is cancelled or fails, fall back to clipboard
      return await copyToClipboard(url);
    }
  } else {
    // No native sharing available, use clipboard
    return await copyToClipboard(url);
  }
};

// Separate clipboard function for cleaner code
const copyToClipboard = async (url) => {
  try {
    await navigator.clipboard.writeText(url);
    return { success: true, method: 'clipboard' };
  } catch (error) {
    console.error('Error copying to clipboard:', error);
    return { success: false, method: 'none' };
  }
};
