import React, { useState } from 'react';
import { SlNote, SlRefresh, SlPaperPlane, SlLike } from "react-icons/sl";
import { commonStyles } from '../styles/globalStyles';

function CalculatorButtons({ 
  onLog, 
  onShare, 
  onReset, 
  logButtonIcon = <SlNote />,
  shareButtonIcon = <SlPaperPlane />,
  resetButtonIcon = <SlRefresh />
}) {
  const [shareText, setShareText] = useState('Share');
  const [currentShareIcon, setCurrentShareIcon] = useState(shareButtonIcon);

  const handleShare = async () => {
    try {
      const result = await onShare();
      console.log('Share result:', result); // For debugging

      if (result?.success) {
        setCurrentShareIcon(<SlLike />);
        if (result.method === 'clipboard') {
          setShareText('Link Copied!');
        } else if (result.method === 'share') {
          setShareText('Shared!');
        }

        // Reset after 2 seconds
        setTimeout(() => {
          setShareText('Share');
          setCurrentShareIcon(shareButtonIcon);
        }, 500);
      }
    } catch (error) {
      console.error('Error in handleShare:', error);
    }
  };

  return (
    <div className={`${commonStyles.actionButtons.container} grid grid-cols-3 gap-4`}>
      <button
        onClick={onLog}
        className={`${commonStyles.actionButtons.button()} ${commonStyles.actionButtons.spacing.first}`}
      >
        <div className="flex items-center justify-center gap-3">
          <span className={commonStyles.actionButtons.buttonIcon}>{logButtonIcon}</span>
          <span className={commonStyles.actionButtons.buttonText}>+Log</span>
        </div>
      </button>
      
      <button
        onClick={handleShare}
        className={`${commonStyles.actionButtons.button()} ${commonStyles.actionButtons.spacing.middle}`}
      >
        <div className="flex items-center justify-center gap-3">
          <span className={commonStyles.actionButtons.buttonIcon}>{currentShareIcon}</span>
          <span className={commonStyles.actionButtons.buttonText}>{shareText}</span>
        </div>
      </button>
      
      <button
        onClick={onReset}
        className={`${commonStyles.actionButtons.button()} ${commonStyles.actionButtons.spacing.last}`}
      >
        <div className="flex items-center justify-center gap-3">
          <span className={commonStyles.actionButtons.buttonIcon}>{resetButtonIcon}</span>
          <span className={commonStyles.actionButtons.buttonText}>Reset</span>
        </div>
      </button>
    </div>
  );
}

export default CalculatorButtons; 